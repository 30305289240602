"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaStatus = void 0;
exports.MediaStatus = {
    CREATED: 'Created',
    UPLOADED: 'Uploaded',
    ERRORED: 'Errored',
    PROCESSING: 'Processing',
    READY: 'Ready',
};
exports.default = exports.MediaStatus;

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="video-player-icon" x="0px" y="0px" viewBox="0 0 20 20">
      <title>Muted</title>
      <path className="st0" d="M5.6,13.3H3.4c-0.8,0-1.4-0.6-1.4-1.4V8.1c0-0.8,0.6-1.4,1.4-1.4h2.2V13.3z" />
      <polygon className="st0" points="5.6,13.3 11.4,17.3 11.4,2.7 5.6,6.7 " />
      <line className="st0" x1="18" y1="12" x2="14" y2="8" />
      <line className="st0" x1="18" y1="8" x2="14" y2="12" />
    </svg>
  );
}

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './App';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampler: (samplingContext) => {
    if (samplingContext?.transactionContext?.name?.includes('/health-check')) {
      return 0.0; // If health check, don't send traces
    }
    return 1.0;
  },
  tracePropagationTargets: ['localhost', /^https:\/\/[a-z0-1.]+.spokenote.com$/i, /^https:\/\/[a-z0-1.]+.sn1.ai$/i],
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

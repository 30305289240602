import React from 'react';

function NoteSkeletonState() {
  return (
    <div className="throbber-wrapper">
      <div className="throbber">
        <div />
        <div />
      </div>
    </div>
  );
}

export default NoteSkeletonState;

import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" className="link-list-icon" x="0px" y="0px" viewBox="0 0 20 20">
      <title>Company</title>
      <path className="st0" d="M15,6.4h-0.8V5c0-1.2-0.9-2.1-2.1-2.1H8C6.8,2.9,5.9,3.9,5.9,5v1.4H5c-1.2,0-2.1,0.9-2.1,2.1V15
	c0,1.2,0.9,2.1,2.1,2.1H15c1.2,0,2.1-0.9,2.1-2.1V8.5C17.1,7.4,16.1,6.4,15,6.4z M6.9,5c0-0.6,0.5-1.1,1.1-1.1H12
	c0.6,0,1.1,0.5,1.1,1.1v1.4H6.9V5z M16.1,15c0,0.6-0.5,1.1-1.1,1.1H5c-0.6,0-1.1-0.5-1.1-1.1V8.5c0-0.6,0.5-1.1,1.1-1.1h1.3h7.3H15
	c0.6,0,1.1,0.5,1.1,1.1V15z"/>
    </svg>
  );
}

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="social-icon" x="0px" y="0px" viewBox="0 0 40 40">
      <title>Facebook</title>
      <circle className="st0" cx="20" cy="20" r="20" />
      <path
        className="st1"
        d="M16.5,33.7h4.9V22.4h3.6l0.7-4.6h-4.4v-3.5c0-1.3,1.2-2,2.4-2H26V8.5l-3.8-0.2
        c-3.6-0.2-5.6,2.6-5.6,6v3.4h-4.1v4.6h4.1V33.7z"
      />
    </svg>
  );
}

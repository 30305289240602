import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" className="nav-icon" x="0px" y="0px" viewBox="0 0 24 24">
      <title>Settings</title>
      <path
        className="st0"
        d="M19.7,7.6c-0.2-0.4-0.5-0.7-0.7-1l0-3.1l-3.1-1.8l-2.6,1.6c-0.8-0.1-1.7-0.1-2.5,0L8.1,1.6L5,3.4l0,3.1
        c-0.5,0.7-1,1.4-1.3,2.2l-2.7,1.5l0,3.6l2.7,1.5c0.2,0.4,0.3,0.8,0.5,1.1s0.5,0.7,0.7,1l0,3.1l3.1,1.8l2.6-1.6
        c0.8,0.1,1.7,0.1,2.5,0l2.6,1.6l3.1-1.8l0-3.1c0.5-0.7,1-1.4,1.3-2.2l2.7-1.5l0-3.6l-2.7-1.5C20.1,8.3,19.9,7.9,19.7,7.6z"
      />
      <path
        className="st1"
        d="M14,15.5c-1.9,1.1-4.4,0.4-5.5-1.5C7.4,12.1,8.1,9.6,10,8.5c1.9-1.1,4.4-0.4,5.5,1.5
        C16.5,11.9,15.9,14.4,14,15.5z"
      />
    </svg>
  );
}

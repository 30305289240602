/* eslint-disable max-len */
import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" id="spokenote-logo" x="0px" y="0px" viewBox="0 0 158 30">
      <title>Spokenote</title>
      <path className="logo-path" d="M0,15.6h9.8v10.2L6,29.6c-0.4,0.4-1,0.1-1-0.4v-3.3H2.3c-1.3,0-2.3-1-2.3-2.3V15.6z M52,10.1
	c1.2,1.3,1.8,3,1.8,4.9c0,2-0.6,3.6-1.8,4.9c-1.2,1.3-2.6,2-4.4,2c-1.4,0-2.7-0.5-3.8-1.4v5.5c0,0.4-0.1,0.6-0.4,0.9
	c-0.2,0.2-0.5,0.4-0.9,0.4h-1.3c-0.4,0-0.7-0.1-0.9-0.4c-0.2-0.2-0.4-0.5-0.4-0.9V9.7c0-0.4,0.1-0.6,0.4-0.9
	c0.2-0.2,0.5-0.4,0.9-0.4h1.3c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.4,0.5,0.4,0.8c1.1-0.9,2.3-1.4,3.8-1.4C49.4,8.2,50.8,8.8,52,10.1z
	 M49.8,15.1c0-0.9-0.3-1.7-0.9-2.3c-0.6-0.6-1.3-0.9-2.1-0.9c-0.8,0-1.5,0.3-2.1,0.9c-0.6,0.6-0.9,1.4-0.9,2.3
	c0,0.9,0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9C49.5,16.7,49.8,16,49.8,15.1z M66.7,10.1c1.4,1.3,2,3,2,4.9
	c0,2-0.7,3.6-2,4.9c-1.3,1.3-3,2-5.1,2c-2,0-3.7-0.7-5.1-2c-1.4-1.3-2-3-2-4.9c0-2,0.7-3.6,2-4.9c1.4-1.3,3-2,5.1-2
	C63.7,8.2,65.4,8.8,66.7,10.1z M64.8,15.1c0-0.9-0.3-1.6-0.9-2.2c-0.6-0.6-1.3-0.9-2.2-0.9c-0.9,0-1.6,0.3-2.2,0.9
	c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.9,0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.2,0.9c0.9,0,1.6-0.3,2.2-0.9C64.5,16.7,64.8,15.9,64.8,15.1z
	 M18.7,4h-7.5v10.3H21v-8C21,5,19.9,4,18.7,4z M11.2,25.8h7.5c1.3,0,2.3-1,2.3-2.3v-7.9h-9.8V25.8z M0,6.3v8h9.8V4H2.3
	C1,4,0,5,0,6.3z M35.1,13.7l-2.4-0.8c-0.7-0.3-1-0.5-1-0.9c0-0.6,0.5-0.9,1.5-0.9c0.7,0,1.3,0.2,1.7,0.6c0.3,0.3,0.7,0.4,1,0.4v0
	l1.6-0.3c0.4-0.1,0.6-0.2,0.8-0.5c0.2-0.3,0.2-0.6,0.1-0.9c-0.8-1.6-2.4-2.4-4.9-2.4c-1.6,0-2.9,0.3-3.9,1c-1,0.7-1.5,1.7-1.5,3.2
	c0,1,0.3,1.8,0.8,2.4c0.5,0.6,1.4,1.1,2.7,1.4l2.4,0.7c0.9,0.3,1.3,0.6,1.3,1c0,0.3-0.2,0.5-0.5,0.7c-0.3,0.2-0.7,0.3-1.2,0.3
	c-1,0-1.6-0.3-2-0.8c-0.3-0.3-0.6-0.5-0.9-0.5h-0.1L29,17.8c-0.4,0.1-0.7,0.2-0.8,0.5c-0.2,0.3-0.2,0.6-0.1,0.9
	c0.8,1.8,2.6,2.7,5.4,2.7c1.6,0,3-0.4,4-1.1c1-0.7,1.5-1.8,1.5-3.1c0-1-0.3-1.8-0.9-2.4C37.4,14.6,36.4,14.1,35.1,13.7z M148.5,10.2
	c1.3,1.3,1.9,2.9,1.9,4.8c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2h-11.3c0.1,1.4,0.7,2.6,1.7,3.5c1,0.9,2.2,1.4,3.7,1.4
	c1.8,0,3.2-0.6,4.2-1.9c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.1,0,0.2,0l0.7,0.2c0.1,0.1,0.2,0.1,0.3,0.3c0,0.1,0,0.2-0.1,0.3
	c-0.6,0.9-1.4,1.6-2.4,2c-1,0.5-2.1,0.7-3.2,0.7c-2,0-3.7-0.6-5-1.9c-1.3-1.3-2-2.9-2-4.8c0-1.9,0.6-3.5,1.9-4.8
	c1.3-1.3,2.9-1.9,4.8-1.9C145.6,8.3,147.2,8.9,148.5,10.2z M148.8,14.2c-0.2-1.3-0.8-2.4-1.7-3.2c-1-0.8-2.1-1.3-3.4-1.3
	c-1.3,0-2.5,0.4-3.4,1.3c-0.9,0.8-1.5,1.9-1.7,3.2H148.8z M136,8.5h-4.6V6.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5
	c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4v2.1H128c-0.3,0-0.5,0.2-0.5,0.5v0.5l0,0c0,0.3,0.2,0.5,0.5,0.5h1.8v8
	c0,1,0.3,1.9,1,2.6c0.7,0.7,1.5,1.1,2.5,1.1h1.5c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-0.6c0-0.1,0-0.2-0.1-0.3
	c-0.1-0.1-0.2-0.1-0.3-0.1h-1.4c-0.6,0-1.1-0.2-1.5-0.7c-0.4-0.4-0.6-1-0.6-1.7V9.9h4.6c0.3,0,0.5-0.2,0.5-0.5V9
	C136.5,8.7,136.4,8.5,136,8.5z M124.5,10.2c1.3,1.3,2,2.9,2,4.8s-0.7,3.5-2,4.8c-1.3,1.3-2.9,1.9-4.9,1.9c-2,0-3.6-0.6-4.9-1.9
	c-1.3-1.3-2-2.9-2-4.8s0.7-3.6,2-4.9c1.3-1.3,2.9-1.9,4.9-1.9C121.6,8.3,123.2,8.9,124.5,10.2z M124.9,15.1c0-1.5-0.5-2.7-1.5-3.7
	c-1-1-2.2-1.5-3.7-1.5c-1.5,0-2.7,0.5-3.7,1.5c-1,1-1.5,2.2-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7c1,1,2.2,1.5,3.7,1.5
	c1.5,0,2.7-0.5,3.7-1.5C124.4,17.8,124.9,16.6,124.9,15.1z M93.9,10.2c1.3,1.4,2,3,2,5c0,0.3-0.1,0.6-0.4,0.9
	c-0.3,0.2-0.6,0.4-1,0.4h-8.6c0.2,0.7,0.6,1.2,1.2,1.7c0.6,0.4,1.3,0.6,2,0.6c0.8,0,1.6-0.2,2.2-0.7c0.3-0.2,0.6-0.3,0.9-0.3
	c0.1,0,0.2,0,0.2,0l1.9,0.4c0.4,0.1,0.6,0.3,0.7,0.5c0.1,0.3,0,0.5-0.2,0.8c-1.4,1.7-3.3,2.5-5.6,2.5c-2.1,0-3.8-0.7-5.2-2
	c-1.4-1.3-2.1-3-2.1-4.9s0.7-3.6,2-4.9c1.3-1.3,3-2,5-2C90.9,8.2,92.6,8.8,93.9,10.2z M92,13.6c-0.2-0.7-0.6-1.2-1.2-1.6
	c-0.6-0.4-1.2-0.6-1.9-0.6c-0.7,0-1.3,0.2-1.8,0.6c-0.5,0.4-0.9,0.9-1.1,1.6H92z M104.6,8.3c-2.3,0-4,0.9-5.1,2.8V9
	c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5c-0.2,0-0.3,0.1-0.4,0.2C98,8.7,97.9,8.9,97.9,9h0v12.1c0,0.2,0.1,0.3,0.2,0.4
	c0.1,0.1,0.2,0.2,0.4,0.2H99c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4V14c0-1.2,0.5-2.2,1.4-3c0.9-0.8,2-1.2,3.4-1.2
	c1.2,0,2.2,0.4,3,1.2c0.8,0.8,1.2,1.8,1.2,3v7.2c0,0.4,0.2,0.5,0.5,0.5h0.5c0.2,0,0.3,0,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4v-7.5
	c0-1.5-0.5-2.8-1.5-3.8C107.4,8.8,106.2,8.3,104.6,8.3z M77.5,14.8l3.9-5.3c0.2-0.3,0.3-0.5,0.2-0.7c-0.1-0.2-0.3-0.3-0.7-0.3h-2
	c-0.4,0-0.7,0.1-1.1,0.3C77.3,9,77,9.2,76.8,9.5l-3,4.1V3.9c0-0.4-0.1-0.6-0.4-0.9c-0.2-0.2-0.5-0.4-0.9-0.4h-1.3
	c-0.4,0-0.7,0.1-0.9,0.4C70.1,3.3,70,3.6,70,3.9v16.5c0,0.3,0.1,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4h1.3c0.4,0,0.7-0.1,0.9-0.4
	c0.2-0.2,0.4-0.5,0.4-0.9V16l3.4,4.6c0.2,0.3,0.5,0.5,0.9,0.7c0.4,0.2,0.8,0.3,1.1,0.3h2.1c0.4,0,0.6-0.1,0.7-0.3
	c0.1-0.2,0-0.4-0.2-0.7L77.5,14.8z M154.9,5.8c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2s0.3,1.6,0.9,2.2
	c0.6,0.6,1.3,0.9,2.2,0.9s1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2s-0.3-1.6-0.9-2.2C156.5,6.2,155.7,5.8,154.9,5.8z M154.9,11.8
	c-0.8,0-1.4-0.3-2-0.8c-0.5-0.5-0.8-1.2-0.8-2c0-0.8,0.3-1.4,0.8-2c0.5-0.5,1.2-0.8,2-0.8c0.8,0,1.4,0.3,2,0.8
	c0.5,0.5,0.8,1.2,0.8,2c0,0.8-0.3,1.4-0.8,2C156.3,11.5,155.6,11.8,154.9,11.8z M153.7,10.4c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1
	h0.2c0.1,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1v-1h0.9l0.6,1c0.1,0.1,0.1,0.1,0.3,0.1h0.2c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1l-0.7-1.1
	c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.8-0.3h-1.3c-0.1,0-0.2,0.1-0.2,0.2
	V10.4z M154.2,9V7.8h1c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2H154.2z"/>
</svg>

  );
}

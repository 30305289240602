import { create } from 'apisauce';

const auth = JSON.parse(localStorage.getItem('auth'));
const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Accept: 'application/json', Authorization: auth?.token ? `Bearer ${auth.token}` : null },
});

export function apiCall(method, url, data) {
  return api[method](url, data).then((response) => response);
}

export function get(url, params) {
  return apiCall('get', url, params);
}

export function post(url, data) {
  return apiCall('post', url, data);
}

export function put(url, data) {
  return apiCall('put', url, data);
}

export function login(email, password) {
  return post('/auth/login', { email, password });
}

export function signup(email, password) {
  return post('/auth/sign-up', { email, password });
}

export function forgotPassword(emailOrPhone) {
  return post('/auth/forgot-password', { emailOrPhone });
}

export function resetPassword(newEmail, newPassword, resetToken) {
  return post('/auth/forgot-password', { newEmail, newPassword, resetToken });
}

export function verifyEmail(verifyToken) {
  return get('/auth/verify-email', { verifyToken });
}

export function resendVerificationEmail() {
  return get('/auth/verify-email', { resend: true });
}

export function createEvent(data) {
  return post('/events', data);
}

export function getFeatures() {
  return get('/features');
}

export function createMedia(data) {
  return post('/media', data);
}

export function updateMedia(mediaId, data = {}) {
  return put(`media/${mediaId}`, data);
}

export function getMyNotes(params) {
  return get('/notes', params);
}

export function updateNote(noteId, data) {
  return put(`/notes/${noteId}`, data);
}

export function getPage(pageId) {
  return get(`/pages/${pageId}`);
}

export function getMyPages(params) {
  return get('/pages', params);
}

export function createPage() {
  return post('/pages');
}

export function updatePage(pageId, data) {
  return put(`/pages/${pageId}`, data);
}

export function getUser(userId = null) {
  return get(`/users/${userId ?? 'me'}`);
}

export function updateUser(userId, data) {
  return put(`/users/${userId}`, data);
}

export function getWorkspace(workspaceId = null) {
  return get(`/workspaces/${workspaceId ?? 'current'}`);
}

export function createSupportTicket() {
  return post('/support-tickets');
}

export default api;

import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" id="spokenote-sticker-scanning" x="0px" y="0px" viewBox="0 0 200 200">
      <title>spokenote sticker scanning</title>
      <g>
        <path d="M172.4,197H187c5.5,0,10-4.5,10-10v-14.6" />
        <path d="M27.6,3H13C7.5,3,3,7.5,3,13v14.6" />
        <path d="M3,172.4V187c0,5.5,4.5,10,10,10h14.6" />
        <path d="M197,27.6V13c0-5.5-4.5-10-10-10h-14.6" />
      </g>
    </svg>
  );
}

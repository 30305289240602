import React from 'react';
import * as Sentry from '@sentry/react';

import ReportIssue from './ReportIssue';

import '../styles/ErrorBoundary.scss';

export function ErrorFallback({ error, componentStack, resetError }) {
  return (
    <div className="error-boundary">
      <div className="error-boundary-inner">
        <h1 className="h2">Something went wrong.</h1>
        <button
          type="button"
          onClick={() => window.location.reload(false)}
          className="btn btn-primary btn-medium"
        >
          Refresh the Page
        </button>
      </div>

      <ReportIssue />
    </div>
  );
}

function ErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;

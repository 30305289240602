import React from 'react';
import { Player } from '@remotion/player';

import { VIDEO_WIDTH, VIDEO_HEIGHT, VIDEO_FPS } from '../DynamicVideoCompositions';
import { VideoMailMergeComposition } from '../DynamicVideoCompositions/VideoMailMergeComposition';

function DynamicVideo({ block, note, onPlayCallback }) {
  const { dynamicVideoType, videoInputs, durationInFrames } = block.data;
  if (!dynamicVideoType) {
    return null;
  }

  let component;
  switch (dynamicVideoType) {
    case 'VideoMailMerge':
      component = VideoMailMergeComposition;
      break;
    default:
      return null;
  }

  return (
    <div className="sn-block sn-block-dynamic-merge-video">
      <Player
        component={component}
        compositionWidth={VIDEO_WIDTH}
        compositionHeight={VIDEO_HEIGHT}
        fps={VIDEO_FPS}
        durationInFrames={durationInFrames}
        controls
        inputProps={videoInputs}
      />
    </div>
  );
}

export default DynamicVideo;

import React from 'react';

function Text({ block }) {
  return (
    <div className="sn-block sn-block-text">
      <p className="paragraph">{block.data.body}</p>
    </div>
  );
}

export default Text;

import React from 'react';
import styles from './VideoPlayer.module.scss';

import useVideoPlayer from '../../hooks/useVideoPlayer';
import IconPlay from '../../images/icon-play-50.svg';
import IconPause from '../../images/icon-pause-50.svg';
import IconUnmuted from '../../images/icon-unmuted.svg';
import IconMuted from '../../images/icon-muted.svg';
import IconFullscreen from '../../images/icon-fullscreen.svg';
import NoteSkeletonState from '../NoteStates/Skeleton';

const sec2Min = (timeInSec) => {
  if (Number.isNaN(timeInSec) || timeInSec === 0 || timeInSec === Infinity) {
    return {
      min: 0,
      sec: 0,
    };
  }
  const min = Math.floor(timeInSec / 60);
  const sec = Math.floor(timeInSec % 60);
  return {
    min,
    sec,
  };
};

let reinitiatePlayPauseTimeout;

function VideoPlayer({
  isReady, src, poster, autoPlay, isVideoPreview, videoElement, onPlayCallback, resolution, isError,
}) {
  const {
    playerState,
    initiatePlay,
    togglePlay,
    handleVideoEnd,
    handleOnTimeUpdate,
    handleVideoProgress,
    // handleVideoSpeed,
    toggleMute,
    toggleFullscreen,
  } = useVideoPlayer(videoElement);
  const [maxWidth, setMaxWidth] = React.useState(500);
  const [isCompleted, setIsCompleted] = React.useState(true);

  const duration = sec2Min(videoElement.current?.duration || 0);
  const currentTime = sec2Min(videoElement.current?.currentTime || 0);

  const [isShowingReinitiatePlayPause, setShowingReinitiatePlayPause] = React.useState(false);

  const videoPlayerBodyReinitiatePlayPause = React.useCallback(() => {
    togglePlay();
    setShowingReinitiatePlayPause(true);
    clearTimeout(reinitiatePlayPauseTimeout);
    reinitiatePlayPauseTimeout = setTimeout(() => {
      setShowingReinitiatePlayPause(false);
    }, 500);
  }, [isShowingReinitiatePlayPause, setShowingReinitiatePlayPause, togglePlay]);

  const measureAspectRatio = React.useCallback((width, height) => {
    const aspectRatio = width / height;
    setMaxWidth(aspectRatio <= 1 ? 320 : 800);
  }, []);

  const onPlay = React.useCallback(() => {
    if (!playerState.isPlayingInitiated) {
      initiatePlay();
    }

    if (isCompleted && onPlayCallback) {
      onPlayCallback();
      setIsCompleted(false);
    }
  }, [playerState.isPlayingInitiated, initiatePlay, onPlayCallback, isCompleted]);

  const onCompleted = React.useCallback(() => {
    setIsCompleted(true);
    handleVideoEnd();
  }, [handleVideoEnd]);

  React.useEffect(() => {
    if (resolution?.width && resolution.height) {
      measureAspectRatio(resolution.width, resolution.height);
    } else if (videoElement.current && videoElement.current.videoWidth && videoElement.current.videoHeight) {
      measureAspectRatio(videoElement.current.videoWidth, videoElement.current.videoHeight);
    } else {
      if (!videoElement.current) return;
      videoElement.current.addEventListener('loadedmetadata', (e) => {
        measureAspectRatio(e.target.videoWidth, e.target.videoHeight);
      });
    }
  }, [resolution, videoElement]);

  return (
    <>
      {!isReady && <NoteSkeletonState />}
      <div className={`${isReady ? 'ready' : 'hide'} video-wrapper ${isError ? 'error' : ''}`} style={{ maxWidth }}>
        {/* disabled track requirement because its user-content */}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          src={src}
          poster={poster}
          ref={videoElement}
          onTimeUpdate={handleOnTimeUpdate}
          playsInline
          controlsList="nodownload"
          onEnded={onCompleted}
          onPlay={onPlay}
          className={`${playerState.isPlaying ? ('playing') : ('paused')}`}
          autoPlay={autoPlay}
        >
          Sorry, your browser does not support embedded videos.
          We can not display your recording preview.
        </video>
        <div
          className={`
            ${styles.videoControlsContainer}
            ${playerState.isPlayingInitiated ? styles.playingInitiated : styles.playingUninitiated}
          `}
        >
          <div className={styles.initiatedGradient} />
          <button
            type="button"
            className={styles.btnReinitiatePlayPauseInteractiveArea}
            onClick={videoPlayerBodyReinitiatePlayPause}
            aria-label={`${playerState.isPlaying ? 'Play' : 'Pause'}`}
          >
            <div
              className={`\
                ${styles.btnReinitiatePlayPause} \
                ${isShowingReinitiatePlayPause ? styles.iconShown : styles.iconHidden}\
              `}
              onTransitionEnd={() => setShowingReinitiatePlayPause(false)}
            >
              <div className={`${styles.btnReinitiatePlayPauseIcon}`}>
                {playerState.isPlaying ? <IconPlay /> : <IconPause />}
              </div>
            </div>
          </button>
          <button
            type="button"
            className={styles.btnInitiatePlayInteractiveArea}
            onClick={initiatePlay}
            aria-label="Play"
            title="Play"
          >
            <div className={styles.btnInitiatePlay}>
              <div className={`${styles.btnInitiatePlayIcon} ${!playerState.isPlaying ? styles.play : styles.pause}`}>
                <IconPlay />
              </div>
            </div>
          </button>
          {(isVideoPreview && !isError) && (<div className="video-preview-label">Review your video before uploading</div>)}
          <div className={styles.videoControlsRow}>
            <button
              type="button"
              className={`${styles.btnPlayPause} ${!playerState.isPlaying ? styles.play : styles.pause}`}
              onClick={togglePlay}
              aria-label={`${!playerState.isPlaying ? 'Play' : 'Pause'}`}
              title={`${!playerState.isPlaying ? 'Play' : 'Pause'}`}
            >
              <div
                className={`${styles.btnPlayPauseIcon} ${!playerState.isPlaying ? styles.play : styles.pause}`}
              >
                <IconPlay />
                <IconPause />
              </div>
            </button>
            <div className={styles.videoControlsBar}>
              <div className={styles.videoTimeAndAction}>
                <div className={styles.videoTime}>
                  <span className={styles.currentTime}>
                    {currentTime.min}
                    :
                    {currentTime.sec.toString().padStart(2, '0')}
                  </span>
                  {!isVideoPreview && (
                    <>
                      <span className={styles.divider}>
                        /
                      </span>
                      <span className={styles.duration}>
                        {duration.min}
                        :
                        {duration.sec.toString().padStart(2, '0')}
                      </span>
                    </>
                  )}
                </div>
                <div className={styles.videoActions}>
                  {/*
                    <select
                      className={styles.btnPlaybackSpeed}
                      value={playerState.speed}
                      onChange={(e) => handleVideoSpeed(e)}
                      aria-label="Playback speed"
                    >
                      <option value="0.50">0.50x</option>
                      <option value="1">1x</option>
                      <option value="1.25">1.25x</option>
                      <option value="2">2x</option>
                    </select>
                  */}
                  <button
                    className={styles.btnMute}
                    type="button"
                    onClick={toggleMute}
                    aria-label={`${!playerState.isMuted ? 'Mute' : 'Unmute'}`}
                    title={`${!playerState.isMuted ? 'Mute' : 'Unmute'}`}
                  >
                    {!playerState.isMuted ? (
                      <IconUnmuted />
                    ) : (
                      <IconMuted />
                    )}
                  </button>
                  <button
                    className={styles.btnFullscreen}
                    type="button"
                    onClick={toggleFullscreen}
                    aria-label="Fullscreen"
                    title="Fullscreen"
                  >
                    <IconFullscreen />
                  </button>
                </div>
              </div>
              {!isVideoPreview && (
                <div className={styles.videoProgress}>
                  <div
                    className={styles.videoProgressTrack}
                  >
                    <div
                      className={styles.videoProgressTrackIndicator}
                      style={{ width: `${playerState.progress}%` }}
                    />
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={playerState.progress || 0}
                      onChange={(e) => handleVideoProgress(e)}
                      className={styles.videoProgressBar}
                      id="range"
                      aria-label="Video progress bar"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.forwardRef((props, ref) => (<VideoPlayer {...props} videoElement={ref} />));

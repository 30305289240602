import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" className="link-list-icon" x="0px" y="0px" viewBox="0 0 20 20">
      <title>Email</title>
      <g>
        <path className="st0" d="M16.5,5.3C16.5,5.2,16.5,5.2,16.5,5.3C16.5,5.2,16.5,5.2,16.5,5.3c-0.4-0.3-0.8-0.5-1.3-0.5H4.8
	c-0.5,0-1,0.2-1.3,0.5c0,0,0,0,0,0c0,0,0,0,0,0C3.2,5.6,2.9,6.1,2.9,6.6v6.8c0,0.5,0.2,1,0.6,1.3c0,0,0,0,0,0c0,0,0,0,0,0
	c0.3,0.3,0.8,0.5,1.3,0.5h10.4c0.5,0,0.9-0.2,1.3-0.5c0,0,0,0,0,0c0,0,0,0,0,0c0.3-0.3,0.6-0.8,0.6-1.3V6.6
	C17.1,6.1,16.8,5.6,16.5,5.3z M12.4,10L16,6.4c0,0.1,0,0.1,0,0.2v6.8c0,0.1,0,0.1,0,0.2L12.4,10z M15.2,5.7
	C15.2,5.7,15.3,5.7,15.2,5.7l-4.4,4.5c-0.4,0.4-1.1,0.4-1.5,0L4.7,5.7c0,0,0.1,0,0.1,0H15.2z M4,13.6c0-0.1,0-0.1,0-0.2V6.6
	c0-0.1,0-0.1,0-0.2L7.6,10L4,13.6z M4.8,14.3C4.8,14.3,4.7,14.3,4.8,14.3l3.5-3.6l0.2,0.2c0.4,0.4,0.9,0.6,1.5,0.6s1.1-0.2,1.5-0.6
	l0.2-0.2l3.6,3.6c0,0-0.1,0-0.1,0H4.8z"/>
      </g>
    </svg>
  );
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventType = void 0;
exports.EventType = {
    PAGE_VIEWED: 'PageViewed',
    CTA_CLICKED: 'CtaClicked',
    VIDEO_PLAYED: 'VideoPlayed',
    VIDEO_PAUSED: 'VideoPaused',
    VIDEO_ENDED: 'VideoEnded',
    VIDEO_SEEKED: 'VideoSeeked',
    PAGE_SUBMITTED: 'PageSubmitted',
    VCARD_DOWNLOADED: 'VCardDownloaded',
};
exports.default = exports.EventType;

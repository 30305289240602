'use client';

import { useState } from 'react';

const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      return value ? JSON.parse(value) : defaultValue;
    } catch (error) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      if (newValue === null) {
        window.localStorage.removeItem(keyName);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(newValue));
      }

      setStoredValue(newValue);
      return true;
    } catch (error) {
      return false;
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;

export function isPhotoUpload(config) {
  return config.permittedUploadTypes?.includes('image/*');
}

export function isVideoUpload(config) {
  return config.permittedUploadTypes?.includes('video/*');
}

export function isPhotoAndVideoUpload(config) {
  return isPhotoUpload(config) && isVideoUpload(config);
}

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="social-icon" x="0px" y="0px" viewBox="0 0 40 40">
      <title>Twitter</title>
      <circle className="st0" cx="20" cy="20" r="20" />
      <g transform="translate(52.390088,-25.058597)">
        <path
          className="st1"
          d="M-44.2,33.6l9.5,12.7l-9.5,10.3h2.1l8.4-9l6.8,9h7.3l-10-13.4l8.9-9.6H-23l-7.7,8.3l-6.2-8.3
          H-44.2z M-41.1,35.2h3.4L-22.9,55h-3.4L-41.1,35.2z"
        />
      </g>
    </svg>
  );
}

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="social-icon" x="0px" y="0px" viewBox="0 0 40 40">
      <title>LinkedIn</title>
      <circle className="st0" cx="20" cy="20" r="20" />
      <path
        className="st1"
        d="M10.9,16H15v13.6h-4.1V16z M12.9,9.1c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5
	c-1.4,0-2.4-1.1-2.4-2.5C10.5,10.2,11.6,9.1,12.9,9.1L12.9,9.1z M17.4,16h4v1.9c0.9-1.3,2.3-2.1,3.9-2.1h1.1c2.6,0,4.6,2.1,4.6,4.6
	v2.1v2.5v4.7h-4v-0.1v-1.2l0,0v-6.1l0,0c-0.1-1.6-1.1-2.8-2.7-2.8l0,0c-1.6,0-2.9,1.2-2.9,2.8l0,0v5.3v2.1h-4V16z"
      />
    </svg>
  );
}

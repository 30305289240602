import React from 'react';
import {
  AbsoluteFill,
  Sequence,
  Img,
  Audio,
  OffthreadVideo,
  useVideoConfig,
  useCurrentFrame,
} from 'remotion';

export const TRANSITION_OVERHANG_SECONDS = 1.5;
export const HOW_LONG_USERS_HESITATE_FRAMES = 5;
export const FADEOUT_OVERHAND_SECONDS = 0.5;
export const CLOSING_CARD_SECONDS = 3;

export function VideoMailMergeComposition(inputProps = {}) {
  const { fps } = useVideoConfig();
  const frame = useCurrentFrame();

  const {
    salutationAudioUrl,
    bodyVideoUrl,
    openingCardImageUrl,
    closingCardImageUrl,
    salutationDurationInSeconds,
    bodyDurationInSeconds,
  } = inputProps;

  const salutationFrames = Math.floor(salutationDurationInSeconds * fps);
  const bodyFrames = Math.floor(bodyDurationInSeconds * fps);
  const transitionFrames = Math.floor(TRANSITION_OVERHANG_SECONDS * fps);
  const forthOfTransitionFrames = Math.floor(transitionFrames / 4);
  const threeForthsOfTransitionFrames = Math.floor(3 * forthOfTransitionFrames);
  const framesBeforeTransition = salutationFrames + threeForthsOfTransitionFrames;
  const fadeOutFrames = Math.floor(FADEOUT_OVERHAND_SECONDS * fps);
  const closingCardFrames = Math.floor(CLOSING_CARD_SECONDS * fps);
  const framesUntilClosingCard = salutationFrames + bodyFrames - (HOW_LONG_USERS_HESITATE_FRAMES * 2) - fadeOutFrames;

  return (
    <AbsoluteFill className="bg-black">
      <Sequence durationInFrames={salutationFrames}>
        <Audio
          src={salutationAudioUrl}
        />
      </Sequence>
      <Sequence durationInFrames={bodyFrames} from={salutationFrames - HOW_LONG_USERS_HESITATE_FRAMES}>
        <AbsoluteFill>
          <OffthreadVideo
            src={bodyVideoUrl}
          />
        </AbsoluteFill>
      </Sequence>
      <Sequence durationInFrames={salutationFrames + transitionFrames}>
        <AbsoluteFill>
          {/* this will fade opacity at the end the length of transitionFrames */}
          <Img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: frame < framesBeforeTransition ? 1 : 1 - ((frame - framesBeforeTransition) / forthOfTransitionFrames),
            }}
            src={openingCardImageUrl}
          />
        </AbsoluteFill>
      </Sequence>
      {closingCardImageUrl && (
        <Sequence durationInFrames={closingCardFrames} from={framesUntilClosingCard}>
          <AbsoluteFill>
            {/* this will fade opacity at the start the length of transitionFrames */}
            <Img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                opacity: Math.min(((frame - framesUntilClosingCard) / fadeOutFrames), 1),
              }}
              src={closingCardImageUrl}
            />
          </AbsoluteFill>
        </Sequence>
      )}
    </AbsoluteFill>
  );
}

import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" className="icon-timer" x="0px" y="0px" viewBox="0 0 16 16">
      <title>Timer</title>
      <circle className="st0" cx="8" cy="9.1" r="6" />
      <line className="st1" x1="8" y1="6.2" x2="8" y2="9.1" />
      <line className="st1" x1="12.3" y1="4.8" x2="13.8" y2="3.3" />
      <line className="st1" x1="5.8" y1="0.9" x2="10.2" y2="0.9" />
    </svg>
  );
}

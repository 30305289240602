import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="social-icon" x="0px" y="0px" viewBox="0 0 40 40">
      <title>Whatsapp</title>
      <circle className="st0" cx="20" cy="20" r="20" />
      <path
        className="st1"
        d="M16,14.8c0.6-0.4,1-0.1,1.3,0.5l0.6,1.5c0,0,0.4,0.8-0.6,1.6c-0.2,0.1-0.2,0.1-0.1,0.4
	c0.2,0.7,1.2,2.8,4.1,4.4c0.3,0.2,0.3,0.2,0.6-0.1c0.2-0.2,0.5-0.5,0.8-0.8c0.5-0.5,0.8-0.6,1.3-0.3c0.3,0.2,0.7,0.5,1,0.8
	c0.3,0.3,0.9,0.6,0.3,1.3l-0.5,0.6c-0.8,0.9-1.4,1-2.4,0.8c-3.8-1-6.5-3.6-7.7-7.4C14.2,16.8,14.7,15.5,16,14.8L16,14.8z M20,8.6
	c6.3,0,11.4,5.1,11.4,11.4c0,6.3-5.1,11.4-11.4,11.4c-1.7,0-3.4-0.3-4.8-1l-6.1,1l0.8-6l-0.1-0.1c-0.8-1.6-1.3-3.4-1.3-5.3
	C8.6,13.7,13.7,8.6,20,8.6L20,8.6z M12.8,28.8l1.6-0.3l1-0.2c1.4,0.8,2.9,1.2,4.6,1.2c5.3,0,9.5-4.3,9.5-9.5c0-5.3-4.3-9.5-9.5-9.5
	s-9.5,4.3-9.5,9.5c0,1.9,0.6,3.6,1.5,5.1L11.8,26l-0.2,1.7l-0.2,1.3L12.8,28.8z"
      />
    </svg>
  );
}

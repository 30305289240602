/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { create as createApi } from 'apisauce';
import { osName, fullBrowserVersion, browserName } from 'react-device-detect';
import { toast } from 'react-hot-toast';
import { PageStatus } from '@repo/constants';
import { configurePage } from '@repo/page-configurator';
import Markdown from 'react-markdown';

import { isPhotoUpload, isVideoUpload } from '../../libs/config';
import Button from '../Button';
import UploadButtons from '../UploadButtons';
import MediaRecorder from '../MediaRecorder';
import useNetwork from '../../hooks/useNetwork';

// import SpokenoteSticker from '../images/spokenote-sticker.svg';
import SpokenoteStickerAnimated from '../../images/spokenote-sticker-animated.svg';
import SpokenoteStickerScanning from '../../images/spokenote-sticker-scanning.svg';
import IconRecord from '../../images/icon-record.svg';
import IconCheck from '../../images/icon-check.svg';
import { createMedia, updateMedia, updatePage } from '../../libs/api';
import { useAuth } from '../../hooks/useAuth';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import useSessionStorage from '../../hooks/useSessionStorage';
import { getUserData } from '../../libs/user-events';

const ONE_SECOND = 1;
const ONE_MINUTE = 60 * ONE_SECOND;

const DEFAULT_INSTRUCTIONS_TITLE = 'Add your video';
const DEFAULT_INSTRUCTIONS_DESCRIPTION = `This page is available for you to add your video.

After adding your video, the person you share this page with will see your video.`;
const SAFARI_18_WARNING_MARKDOWN = `**Mobile Safari 18 Warning**

Mobile Safari versions 18.0.1 - 18.1.1 have an issue that may cause uploads to fail.
We recommend upgrading the OS on your device to 18.2 or newer. Alternatively,
make sure you are connected to WiFi or use an alternative browser such as
[Google Chrome](https://apps.apple.com/us/app/google-chrome/id535886823).`;

const badSafari18Versions = ['18.0.1', '18.1', '18.1.1'];
const isBadSafari18Version = osName === 'iOS' && browserName === 'Mobile Safari' && badSafari18Versions.includes(fullBrowserVersion);

async function uploadMedia(file, page, config, options, isRecording = false) {
  const userData = await getUserData();
  const { hasReadReceipt, pageName } = options;
  const body = {
    fileName: file.name,
    fileType: file.type,
    fileSize: file.size,
    fileLastModified: file.lastModified,
    pageId: page.id,
    metadata: {
      isRecording,
      isPhoto: isPhotoUpload(config),
      pageConfig: {
        hasReadReceipt,
        pageName,
      },
      ...userData,
    },
  };

  const response = await createMedia(body);
  if (!response.ok) {
    throw new Error('Bad response from server while created records.');
  }

  response.data.file = file;
  return response;
}

function NoteCreateState({ page, setPage, setLocalPageOwner }) {
  const { currentUser } = useAuth();
  const isOnline = useNetwork();
  const config = React.useMemo(() => configurePage(page), [page]);
  const [, setSubmissionFlag] = useSessionStorage(`submitted-${page.id}`, false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Animation states
  const [isScanning, setIsScanning] = useState(true);

  // Instructions video
  const [loadingInstructionVideo, setLoadingInstructionVideo] = React.useState(true);
  const instructionVideoRef = React.useRef(null);

  // Video-specific
  const videoPreviewRef = React.useRef(null);
  const [isRecorderOpen, setIsRecorderOpen] = useState(false);
  const [stream, setStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaBlob, setMediaBlob] = useState(null);
  const [isCameraAvailable, setIsCameraAvailable] = useState(true);
  const [isVideoTooLong, setIsVideoTooLong] = useState(false);

  // Image/Video upload
  const [uploadProgresses, setUploadProgress] = React.useReducer((state, change) => {
    if (Object.keys(change).length === 0) return {};
    return { ...state, ...change };
  }, {});
  const [overallProgress, setOverallProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  // Upload form fields
  const pageNameField = useRef(null);
  const hasReadReceiptField = useRef(null);

  const validateMaxUploadLimit = useCallback((fileList) => {
    const maxFiles = config.mediaUploadLimit;
    if (fileList.length > maxFiles) {
      toast.error(`You can only upload up to ${maxFiles} photo${maxFiles === 1 ? '' : 's'}.`);
      return true;
    }
    return false;
  }, [config]);

  const validateMaxVideoLength = useCallback(() => {
    if (isVideoTooLong) {
      const timeLimit = config.videoTimeLimitInMinutes < 1
        ? Math.round(config.videoTimeLimitInMinutes * ONE_MINUTE)
        : config.videoTimeLimitInMinutes;
      const unitOfTime = config.videoTimeLimitInMinutes < 1 ? 'second' : 'minute';
      const msg = `Your video is over the ${timeLimit} ${unitOfTime}${timeLimit === 1 ? '' : 's'} time limit. Please click "Cancel" below to record or upload a new video.`;
      if (files.length > 1) {
        setErrorMessage(`One of your videos is too long. ${msg}`);
      } else {
        setErrorMessage(msg);
      }
      return true;
    }
    return false;
  }, [isVideoTooLong, files, config]);

  const attachNote = useCallback(async (noteId) => {
    try {
      const { ok, data } = await updatePage(page.id, { noteId });
      if (ok) {
        // stop events from being tracked
        setPage({
          ...data.page,
          noTracking: true,
        });
        toast.success('Note attached successfully.');
      }
    } catch {
      toast.error('Issue attaching note. Please try again.');
    }
  }, [page]);

  const getCombinedProgress = useCallback(() => {
    // combine each upload progress and convert to percentage
    const progressValues = Object.values(uploadProgresses);
    const totalProgress = progressValues.reduce((acc, val) => acc + val, 0);
    const totalFiles = files.length;
    const totalProgressPercentage = (totalProgress / totalFiles) * 100;
    const totalProgressNormalized = totalProgressPercentage / 100;
    return Math.min((totalProgressNormalized + (totalFiles > 0 ? overallProgress : 0)), 1);
  }, [uploadProgresses, files]);

  const onClickUpload = useCallback(async () => {
    // Validate the files
    let invalid = false;
    invalid = invalid || validateMaxVideoLength();
    invalid = invalid || validateMaxUploadLimit(files);
    if (invalid) return;

    setUploadProgress({}); // reset all progresses
    setOverallProgress(0);
    setIsUploading(true);

    setPage({
      ...page,
      noTracking: true,
    });

    const options = {
      hasReadReceipt: hasReadReceiptField.current?.checked,
      pageName: pageNameField.current?.value,
    };

    try {
      setOverallProgress(0.01); // 1% means we are about to create the media record
      const mediaCreates = await files.map((file) => uploadMedia(file, page, config, options, isRecording));

      setOverallProgress(0.02); // 2% means we created all the media records

      const mediaUploads = (await Promise.all(mediaCreates)).map(async (response) => {
        const { mediaId, file, uploadUrl, uploadFields } = response.data;
        setUploadProgress({ [mediaId]: 0 }); // Initialize the progress for each media record

        const formData = new FormData();
        Object.entries(uploadFields).forEach(([k, v]) => { formData.append(k, v); });
        formData.append('file', file);

        try {
          const genericApi = createApi({});
          return genericApi.post(uploadUrl, formData, {
            onUploadProgress: ({ loaded, total }) => {
              setUploadProgress({ [mediaId]: (loaded / total) });
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } catch (err) {
          toast.error('Issue uploading file. Please try again.');
          setIsUploading(false);
          return updateMedia(mediaId, { errored: true });
        }
      });

      const uploadResponses = await Promise.all(mediaUploads);
      const erroredUploads = uploadResponses.some((response) => !response.ok);
      if (erroredUploads) {
        setIsUploading(false);
        toast.error('Issue uploading file. Please try again.');
        return;
      }

      window.onbeforeunload = null;
      setOverallProgress(1); // 100% means we are done
      if (!currentUser) {
        setLocalPageOwner(true);
      }

      setSubmissionFlag(true);

      setPage({ ...page, pageStatus: PageStatus.LOCKED });
    } catch (err) {
      setIsUploading(false);
      toast.error('Issue generating upload URL. Please try again.');
    }
  }, [files, currentUser, pageNameField.current, hasReadReceiptField.current, page, setPage, isVideoTooLong]);

  const onClickOpenCamera = useCallback(async () => {
    try {
      const mediaContraints = { video: { width: 1280, height: 720, frameRate: 30 }, audio: true };
      const cameraStream = await navigator.mediaDevices.getUserMedia(mediaContraints);
      window.onbeforeunload = () => true;
      setIsRecorderOpen(true);
      setStream(cameraStream);
    } catch (err) {
      if (err.name === 'NotFoundError') {
        setIsCameraAvailable(false);
      }
    }
  }, []);

  const onClickRedo = useCallback(() => {
    setMediaBlob(null);
    setFiles([]);
    onClickOpenCamera();
  });

  const onClickCancel = useCallback(async () => {
    window.onbeforeunload = null;
    setIsRecorderOpen(false);
    setMediaBlob(null);
    setFiles([]);

    stream?.getTracks().forEach((track) => {
      track.stop();
    });
    setStream(null);
  }, [stream]);

  const onFileSelection = useCallback((event) => {
    const fileList = [...event.target.files];
    setIsVideoTooLong(false);
    setErrorMessage(null);
    setFiles(fileList);

    validateMaxUploadLimit(fileList);

    if (isVideoUpload(config)) {
      fileList.forEach((file) => {
        const blob = new Blob([file], { type: file.type });
        const elm = document.createElement('video');
        elm.preload = 'metadata';
        elm.onloadedmetadata = () => {
          const timeLimit = config.videoTimeLimitInMinutes;
          if (elm.duration > (timeLimit * ONE_MINUTE + ONE_SECOND)) {
            setIsVideoTooLong(true);
          }
        };
        elm.src = URL.createObjectURL(blob);
      });
    }
  }, []);

  useEffect(() => {
    validateMaxVideoLength();
  }, [isVideoTooLong]);

  useEffect(() => {
    if (instructionVideoRef?.current) {
      instructionVideoRef.current?.addEventListener('loadedmetadata', () => {
        setLoadingInstructionVideo(false);
      });
    }
  }, [instructionVideoRef]);

  useEffect(() => {
    setTimeout(() => {
      setIsScanning(false);
    }, 1000);

    return () => {
      onClickCancel();
    };
  }, []);

  let image;
  if (config.topBanner) {
    image = (
      <img
        src={config.topBanner.imageUrl}
        alt={config.topBanner.imageAlt || 'Top Banner'}
        style={config.topBanner.maxWidth ? { maxWidth: `${config.topBanner.maxWidth}px` } : {}}
      />
    );
  }

  return (
    <div>
      {config.topBanner && (
        <div className="sponsor-banner">
          {config.topBanner.linkUrl ? (
            <a href={config.topBanner.linkUrl} target="_blank" rel="noreferrer">
              {image}
            </a>
          ) : (image)}
        </div>
      )}

      {isBadSafari18Version && (
        <div className="alert alert-error">
          <Markdown>
            {SAFARI_18_WARNING_MARKDOWN}
          </Markdown>
        </div>
      )}

      {(!isRecorderOpen && files.length === 0 && !isUploading && isOnline) && (
        <>
          {config.instructions?.videoUrl && (
            <div className="spokenote-video-visual">
              <VideoPlayer
                ref={instructionVideoRef}
                src={config.instructions?.videoUrl}
                poster={config.instructions?.videoThumbnailUrl}
                isReady={!loadingInstructionVideo}
              />
            </div>
          )}
          {!config.instructions?.videoUrl && !config.instructions?.isStickerVisualDisabled && (
            <div className={`spokenote-sticker-visual ${isScanning ? 'animated' : ''}`}>
              <SpokenoteStickerScanning />
              <SpokenoteStickerAnimated />
            </div>
          )}
          <div className="spokenote-instructions spokenote-sticker-visual-title">
            <h4 className="color-primary">
              {config.instructions?.title || DEFAULT_INSTRUCTIONS_TITLE}
            </h4>
            <div className="instructions-description" style={{ textAlign: config.instructions?.descriptionTextAlignment || 'center' }}>
              <Markdown>
                {config.instructions?.description || DEFAULT_INSTRUCTIONS_DESCRIPTION}
              </Markdown>
            </div>
          </div>

          { !config.isUploadsDisabled && (
            <UploadButtons
              config={config}
              isCameraAvailable={isCameraAvailable}
              onClickOpenCamera={onClickOpenCamera}
              onFileSelection={onFileSelection}
            />
          )}

          { config.defaultNotes.length > 0 && (
            <div className="add-video-defaults">
              {page.config.defaultNotes.map(({ title, noteId }) => (
                <Button onClick={() => attachNote(noteId)} ariaLabel={title}>{title}</Button>
              ))}
            </div>
          )}

          {config.bottomBanner && (
            <div className="sponsor-banner">
              {config.bottomBanner.linkUrl ? (
                <a href={config.bottomBanner.linkUrl} target="_blank" rel="noreferrer">
                  <img src={config.bottomBanner.imageUrl} alt={config.bottomBanner.imageAlt || 'Bottom Banner'} />
                </a>
              ) : (
                <img src={config.bottomBanner.imageUrl} alt={config.bottomBanner.imageAlt || 'Bottom Banner'} />
              )}
            </div>
          )}
        </>
      )}

      {!isOnline && (
        <div className="error">
          {!config.instructions?.isStickerVisualDisabled && (
            <div className="spokenote-sticker-visual">
              <SpokenoteStickerAnimated />
            </div>
          )}
          <div className="spokenote-sticker-visual-title">
            <h4 className="color-primary">Connection Issue</h4>
            <p>
              It appears you are not connected to the internet, please check your network connection and try again.
            </p>
          </div>
        </div>
      )}

      {(isUploading && isOnline) && (
        <>
          {!config.instructions?.isStickerVisualDisabled && (
            <div className="spokenote-sticker-visual animated">
              <SpokenoteStickerAnimated />
            </div>
          )}
          <div className="spokenote-sticker-visual-title">
            <h4 className="color-primary">
              Uploading your
              {' '}
              {page.campaign?.config?.isPhotoUpload ? 'photos' : 'video'}
            </h4>
          </div>
          <div className="upload-progress-bar-container">
            <div className="upload-progress-bar">
              <div className="progress" style={{ width: `${getCombinedProgress() * 100}%` }}>
                <div className="progress-percentage">
                  {Math.round(getCombinedProgress() * 100)}
                  %
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!isUploading && isVideoUpload(config) && files?.map((file) => (
        <React.Fragment key={file.name}>
          {errorMessage && (
            <div className="video-error-message">
              {errorMessage}
            </div>
          )}

          <VideoPlayer
            ref={videoPreviewRef}
            key="playback"
            src={URL.createObjectURL(file)}
            isReady
            isVideoPreview
            autoPlay
            isError={isVideoTooLong}
          />
          {(currentUser && !isVideoTooLong) && (
            <div className="optional-video-fields">
              <div className="read-receipt-wrapper">
                <label className="form-control" htmlFor="read-receipt">
                  <input
                    type="checkbox"
                    id="read-receipt"
                    defaultChecked
                    ref={hasReadReceiptField}
                  />
                  Send me a notification when this Spokenote page is viewed for the first time
                </label>
              </div>
              <div className="form-field center-content">
                <label htmlFor="note-title">
                  <input
                    type="text"
                    id="note-title"
                    placeholder="Page Title (optional)"
                    ref={pageNameField}
                    maxLength={60}
                  />
                  <div className="helper-text mb-10">
                    This title is only displayed to you for your reference. The title allows for 60 characters.
                  </div>
                </label>
              </div>

            </div>
          )}
        </React.Fragment>
      ))}

      {!isUploading && isPhotoUpload(config) && files?.map((file) => (
        <React.Fragment key={file.name}>
          <div className="ready video-wrapper">
            <img src={URL.createObjectURL(file)} alt="Capture Preview" />
          </div>
          {currentUser && (
            <div className="optional-video-fields">
              <div className="read-receipt-wrapper">
                <label className="form-control" htmlFor="read-receipt">
                  <input
                    type="checkbox"
                    id="read-receipt"
                    defaultChecked
                    ref={hasReadReceiptField}
                  />
                  Send me a notification when this Spokenote photo is viewed for the first time
                </label>
              </div>
              <div className="form-field center-content">
                <label htmlFor="note-title">
                  <input
                    type="text"
                    id="note-title"
                    placeholder="Page Title (optional)"
                    ref={pageNameField}
                    maxLength={60}
                  />
                  <div className="helper-text mb-10">
                    This title is only displayed to you for your reference. The title allows for 60 characters.
                  </div>
                </label>
              </div>

            </div>
          )}
        </React.Fragment>
      ))}

      {isRecorderOpen && !isUploading && (
        <MediaRecorder
          isPhoto={isPhotoUpload(config)}
          stream={stream}
          setStream={setStream}
          mediaBlob={mediaBlob}
          setMediaBlob={setMediaBlob}
          setFiles={setFiles}
          onClickCancel={onClickCancel}
          timeLimitInSeconds={config.videoTimeLimitInMinutes * ONE_MINUTE}
          setIsRecording={setIsRecording}
        />
      )}

      {!isUploading && (
        <div className="video-actions-container">

          {(mediaBlob) && (
            <Button onClick={onClickRedo} primaryOutline ariaLabel="Redo">
              <IconRecord />
              Redo
            </Button>
          )}

          {(files.length > 0 && !isRecorderOpen) && (
            <Button onClick={onClickCancel} primaryOutline ariaLabel="Cancel">Cancel</Button>
          )}

          {(files.length > 0) && (
            <Button onClick={onClickUpload} ariaLabel="Upload" disabled={isVideoTooLong}>
              <IconCheck />
              Upload
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default NoteCreateState;

import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" className="link-list-icon" x="0px" y="0px" viewBox="0 0 20 20">
      <title>Text</title>
      <g>
        <path className="st0" d="M16.5,5.3h-13C3.2,5.3,3,5.1,3,4.8s0.2-0.5,0.5-0.5h13c0.3,0,0.5,0.2,0.5,0.5S16.8,5.3,16.5,5.3z"/>
      </g>
      <g>
        <path className="st0" d="M16.5,10.5h-13C3.2,10.5,3,10.3,3,10s0.2-0.5,0.5-0.5h13c0.3,0,0.5,0.2,0.5,0.5S16.8,10.5,16.5,10.5z"/>
      </g>
      <g>
        <path className="st0" d="M13.6,15.7H3.5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h10.1c0.3,0,0.5,0.2,0.5,0.5S13.9,15.7,13.6,15.7z"/>
      </g>
    </svg>
  );
}

import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" id="spokenote-sticker-animated" x="0px" y="0px" viewBox="0 0 200 200">
      <title>spokenote sticker</title>
      <path
        id="sticker-outer"
        className="st0"
        d="M44.2,30.2c-7.7,0-14,6.3-14,14v111.6c0,7.7,6.3,14,14,14h14v14c0,2.9,3.3,4.5,5.6,2.8
          l22.3-16.7h69.8c7.7,0,14-6.3,14-14V93.4l-63.2-63.2H44.2z"
      />
      <path id="sticker-fold" className="st1" d="M120.6,93.4h49.1l-63.1-63.2v49.1C106.7,87.1,113,93.4,120.6,93.4z" />
      <path id="sticker-inner" className="st1" d="M120.6,93.4c-7.7,0-14-6.3-14-14v-29H48.6v102.8h102.8V93.4h-4.7H120.6z" />
      <path
        id="reg2"
        className="st2"
        d="M86.8,115H60v26.9h26.8C86.8,141.8,86.8,115,86.8,115z M83,137.9H63.9v-19.1H83V137.9z M67.7,122.7
          h11.5v11.5H67.7V122.7z"
      />
      <path
        id="reg1"
        className="st2"
        d="M86.8,61.8H60v26.8h26.8C86.8,88.6,86.8,61.8,86.8,61.8z M83,84.9H63.9V65.7H83V84.9z M67.7,69.5
          h11.5v11.5H67.7V69.5z"
      />
      <g>
        <rect id="r001" x="90.5" y="61.8" className="st2" width="3.8" height="3.8" />
        <rect id="r002" x="94.3" y="61.8" className="st2" width="3.8" height="3.8" />
        <rect id="r003" x="98.1" y="61.8" className="st2" width="3.8" height="3.8" />
        <rect id="r004" x="102" y="61.8" className="st2" width="3.8" height="3.8" />
        <rect id="r005" x="94.3" y="65.6" className="st2" width="3.8" height="3.8" />
        <rect id="r006" x="102" y="65.6" className="st2" width="3.8" height="3.8" />
        <rect id="r007" x="90.5" y="73.3" className="st2" width="3.8" height="3.8" />
        <rect id="r008" x="102" y="73.3" className="st2" width="3.8" height="3.8" />
        <rect id="r009" x="94.3" y="77.1" className="st2" width="3.8" height="3.8" />
        <rect id="r010" x="98.1" y="77.1" className="st2" width="3.8" height="3.8" />
        <rect id="r011" x="102" y="77.1" className="st2" width="3.8" height="3.8" />
        <rect id="r012" x="90.5" y="84.7" className="st2" width="3.8" height="3.8" />
        <rect id="r013" x="98.1" y="84.7" className="st2" width="3.8" height="3.8" />
        <rect id="r015" x="94.3" y="88.5" className="st2" width="3.8" height="3.8" />
        <rect id="r021" x="90.5" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r022" x="94.3" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r023" x="98.1" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r024" x="102" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r035" x="90.5" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r036" x="94.3" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r037" x="98.1" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r059" x="94.3" y="103.7" className="st2" width="3.8" height="3.8" />
        <rect id="r060" x="102" y="103.7" className="st2" width="3.8" height="3.8" />
        <rect id="r048" x="90.5" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r049" x="102" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r014" x="105.8" y="84.7" className="st2" width="3.8" height="3.8" />
        <rect id="r016" x="105.8" y="88.5" className="st2" width="3.8" height="3.8" />
        <rect id="r025" x="105.8" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r026" x="109.6" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r038" x="105.8" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r039" x="109.6" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r040" x="113.4" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r061" x="105.8" y="103.7" className="st2" width="3.8" height="3.8" />
        <rect id="r062" x="113.4" y="103.7" className="st2" width="3.8" height="3.8" />
        <rect id="r050" x="105.8" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r051" x="113.4" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r027" x="124.8" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r041" x="124.8" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r042" x="132.4" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r052" x="121" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r028" x="136.2" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r043" x="136.2" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r054" x="136.2" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r053" x="132.4" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r017" x="67.7" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r029" x="60" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r030" x="67.7" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r031" x="71.5" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r055" x="60" y="103.7" className="st2" width="3.8" height="3.8" />
        <rect id="r056" x="63.9" y="103.7" className="st2" width="3.8" height="3.8" />
        <rect id="r044" x="63.9" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r045" x="67.7" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r018" x="75.3" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r019" x="79.1" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r020" x="82.9" y="92.3" className="st2" width="3.8" height="3.8" />
        <rect id="r032" x="75.3" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r033" x="79.1" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r034" x="86.7" y="96.1" className="st2" width="3.8" height="3.8" />
        <rect id="r057" x="75.3" y="103.7" className="st2" width="3.8" height="3.8" />
        <rect id="r058" x="79.1" y="103.7" className="st2" width="3.8" height="3.8" />
        <rect id="r046" x="75.3" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r047" x="82.9" y="99.9" className="st2" width="3.8" height="3.8" />
        <rect id="r067" x="90.5" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r068" x="94.3" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r069" x="98.1" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r075" x="90.5" y="111.4" className="st2" width="3.8" height="3.8" />
        <rect id="r076" x="94.3" y="111.4" className="st2" width="3.8" height="3.8" />
        <rect id="r077" x="98.1" y="111.4" className="st2" width="3.8" height="3.8" />
        <rect id="r078" x="102" y="111.4" className="st2" width="3.8" height="3.8" />
        <rect id="r091" x="90.5" y="119" className="st2" width="3.8" height="3.8" />
        <rect id="r092" x="94.3" y="119" className="st2" width="3.8" height="3.8" />
        <rect id="r084" x="94.3" y="115.2" className="st2" width="3.8" height="3.8" />
        <rect id="_x38_5" x="98.1" y="115.2" className="st2" width="3.8" height="3.8" />
        <rect id="r070" x="105.8" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r071" x="109.6" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r072" x="113.4" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r079" x="109.6" y="111.4" className="st2" width="3.8" height="3.8" />
        <rect id="r080" x="117.2" y="111.4" className="st2" width="3.8" height="3.8" />
        <rect id="r093" x="109.6" y="119" className="st2" width="3.8" height="3.8" />
        <rect id="r094" x="117.2" y="119" className="st2" width="3.8" height="3.8" />
        <rect id="r086" x="113.4" y="115.2" className="st2" width="3.8" height="3.8" />
        <rect id="r087" x="117.2" y="115.2" className="st2" width="3.8" height="3.8" />
        <rect id="r073" x="121" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r81" x="124.8" y="111.4" className="st2" width="3.8" height="3.8" />
        <rect id="r082" x="132.4" y="111.4" className="st2" width="3.8" height="3.8" />
        <rect id="r095" x="121" y="119" className="st2" width="3.8" height="3.8" />
        <rect id="r096" x="124.8" y="119" className="st2" width="3.8" height="3.8" />
        <rect id="r088" x="124.8" y="115.2" className="st2" width="3.8" height="3.8" />
        <rect id="r074" x="136.2" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r083" x="136.2" y="111.4" className="st2" width="3.8" height="3.8" />
        <rect id="r097" x="136.2" y="119" className="st2" width="3.8" height="3.8" />
        <rect id="r090" x="136.2" y="115.2" className="st2" width="3.8" height="3.8" />
        <rect id="r089" x="132.4" y="115.2" className="st2" width="3.8" height="3.8" />
        <rect id="r105" x="98.1" y="126.6" className="st2" width="3.8" height="3.8" />
        <rect id="r106" x="102" y="126.6" className="st2" width="3.8" height="3.8" />
        <rect id="r098" x="90.5" y="122.8" className="st2" width="3.8" height="3.8" />
        <rect id="r099" x="94.3" y="122.8" className="st2" width="3.8" height="3.8" />
        <rect id="r100" x="98.1" y="122.8" className="st2" width="3.8" height="3.8" />
        <rect id="r107" x="105.8" y="126.6" className="st2" width="3.8" height="3.8" />
        <rect id="r101" x="109.6" y="122.8" className="st2" width="3.8" height="3.8" />
        <rect id="r102" x="117.2" y="122.8" className="st2" width="3.8" height="3.8" />
        <rect id="r108" x="128.6" y="126.6" className="st2" width="3.8" height="3.8" />
        <rect id="r109" x="132.4" y="126.6" className="st2" width="3.8" height="3.8" />
        <rect id="r103" x="128.6" y="122.8" className="st2" width="3.8" height="3.8" />
        <rect id="r104" x="136.2" y="122.8" className="st2" width="3.8" height="3.8" />
        <rect id="r110" x="90.5" y="130.4" className="st2" width="3.8" height="3.8" />
        <rect id="r111" x="98.1" y="130.4" className="st2" width="3.8" height="3.8" />
        <rect id="r115" x="94.3" y="134.2" className="st2" width="3.8" height="3.8" />
        <rect id="r116" x="98.1" y="134.2" className="st2" width="3.8" height="3.8" />
        <rect id="r112" x="105.8" y="130.4" className="st2" width="3.8" height="3.8" />
        <rect id="r117" x="105.8" y="134.2" className="st2" width="3.8" height="3.8" />
        <rect id="r118" x="109.6" y="134.2" className="st2" width="3.8" height="3.8" />
        <rect id="r119" x="113.4" y="134.2" className="st2" width="3.8" height="3.8" />
        <rect id="r122" x="105.8" y="138" className="st2" width="3.8" height="3.8" />
        <rect id="r123" x="113.4" y="138" className="st2" width="3.8" height="3.8" />
        <rect id="r113" x="121" y="130.4" className="st2" width="3.8" height="3.8" />
        <rect id="r120" x="128.6" y="134.2" className="st2" width="3.8" height="3.8" />
        <rect id="r121" x="132.4" y="134.2" className="st2" width="3.8" height="3.8" />
        <rect id="r124" x="128.6" y="138" className="st2" width="3.8" height="3.8" />
        <rect id="r114" x="136.2" y="130.4" className="st2" width="3.8" height="3.8" />
        <rect id="r126" x="136.2" y="138" className="st2" width="3.8" height="3.8" />
        <rect id="r125" x="132.4" y="138" className="st2" width="3.8" height="3.8" />
        <rect id="r063" x="63.9" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r064" x="79.1" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r065" x="82.9" y="107.6" className="st2" width="3.8" height="3.8" />
        <rect id="r066" x="86.7" y="107.6" className="st2" width="3.8" height="3.8" />
      </g>
    </svg>
  );
}

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="btn-icon icon-record" x="0px" y="0px" viewBox="0 0 26 24">
      <title>capture photo</title>
      <circle class="st0" cx="13.2" cy="12.9" r="4.3"/>
      <path class="st1" d="M18.2,5.4l-1.1-2c-0.4-0.6-1-1-1.7-1H11c-0.7,0-1.4,0.4-1.7,1l-1.1,2c-0.4,0.6-1,1-1.7,1H5.1c-1.1,0-2,0.9-2,2
	v11.3c0,1.1,0.9,2,2,2h16.2c1.1,0,2-0.9,2-2V8.4c0-1.1-0.9-2-2-2h-1.4C19.2,6.4,18.5,6,18.2,5.4z"/>
    </svg>
  );
}

import React from 'react';
import { isMobile } from 'react-device-detect';

import Button from './Button';
import { isPhotoAndVideoUpload, isPhotoUpload } from '../libs/config';

import IconCapture from '../images/icon-capture-photo.svg';
import IconRecord from '../images/icon-record.svg';
import IconTimer from '../images/icon-timer.svg';
import IconUpload from '../images/icon-upload.svg';

const ONE_SECOND = 1;
const ONE_MINUTE = 60 * ONE_SECOND;

function VideoAndPhotoLimitDescription({ config }) {
  const limit = config.mediaUploadLimit;
  const timeLimit = config.videoTimeLimitInMinutes;
  return (
    <div className="time-limit">
      <IconTimer />
      <div>
        Upload up to
        {' '}
        {limit}
        {' '}
        video
        {limit === 1 ? '' : 's'}
        {' '}
        or photo
        {limit === 1 ? '' : 's'}
        .
        <br />
        Videos are limited to
        {' '}
        {timeLimit}
        {' '}
        minute
        {timeLimit === 1 ? '' : 's'}
        .
      </div>
    </div>
  );
}

function PhotoLimitDescription({ config }) {
  const limit = config.mediaUploadLimit;
  return (
    <div className="time-limit">
      <IconCapture />
      <div>
        Upload up to
        {' '}
        {limit}
        {' '}
        photo
        {limit === 1 ? '' : 's'}
      </div>
    </div>
  );
}

function VideoLimitDescription({ config }) {
  const timeLimit = config.videoTimeLimitInMinutes < 1
    ? Math.round(config.videoTimeLimitInMinutes * ONE_MINUTE)
    : config.videoTimeLimitInMinutes;
  const unitOfTime = config.videoTimeLimitInMinutes < 1 ? 'second' : 'minute';

  return (
    <div className="time-limit">
      <IconTimer />
      <div>
        {timeLimit}
        {' '}
        {unitOfTime}
        {' '}
        time limit
      </div>
    </div>
  );
}

function MobileRecordButton({ config, onFileSelection }) {
  return (
    <Button ariaLabel="Record">
      <label htmlFor="record-file" style={{ position: 'relative', width: '100%', height: '100%' }}>
        <IconRecord />
        <span>Record</span>
        <input
          capture="user"
          id="record-file"
          type="file"
          accept={config.permittedUploadTypes?.join(',')}
          className="hide"
          onChange={onFileSelection}
        />
      </label>
    </Button>
  );
}

function DesktopRecordButton({ config, onClickOpenCamera }) {
  if (isPhotoUpload(config)) {
    return (
      <Button onClick={onClickOpenCamera} ariaLabel="Capture">
        <IconCapture />
        <span>Capture</span>
      </Button>
    );
  }

  return (
    <Button onClick={onClickOpenCamera} ariaLabel="Record">
      <IconRecord />
      <span>Record</span>
    </Button>
  );
}

export default function UploadButtons({ config, isCameraAvailable, onClickOpenCamera, onFileSelection }) {
  let LimitDescription;
  if (isPhotoAndVideoUpload(config)) {
    LimitDescription = VideoAndPhotoLimitDescription;
  } else if (isPhotoUpload(config)) {
    LimitDescription = PhotoLimitDescription;
  } else {
    LimitDescription = VideoLimitDescription;
  }

  return (
    <>
      <div className="spokenote-sticker-visual-title">
        <LimitDescription config={config} />
      </div>

      <div className="add-video-options">
        {/* HIDE RECORDING FOR NOW */}
        {/* Only allow recording when only 1 video can be uploaded */}
        {isCameraAvailable && config.mediaUploadLimit === 1 && (
          <div className="record">
            {isMobile ? (
              <MobileRecordButton config={config} onFileSelection={onFileSelection} />
            ) : (
              <DesktopRecordButton config={config} onClickOpenCamera={onClickOpenCamera} />
            )}
          </div>
        )}

        {!isCameraAvailable && (
          <div>Error accessing camera</div>
        )}

        <div className="upload">
          <Button ariaLabel="Upload">
            <label htmlFor="upload-file" style={{ position: 'relative', width: '100%', height: '100%' }}>
              <IconUpload />
              Upload
              <input
                id="upload-file"
                type="file"
                accept={config.permittedUploadTypes?.join(',')}
                multiple={config.mediaUploadLimit > 1}
                className="hide"
                onChange={onFileSelection}
              />
            </label>
          </Button>
        </div>
      </div>
    </>
  );
}

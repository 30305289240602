/* eslint-disable max-len */
import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" id="spokenote-logo-footer" x="0px" y="0px" viewBox="0 0 86 16">
      <title>spokenote</title>
      <g>
        <g>
          <path
            className="logo-path"
            d="M38.3,11.2c-0.1-0.1-0.2-0.3-0.2-0.5V2.2c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2h0.7
        c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v5l1.6-2.1c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.6-0.2H44
        c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0,0.2-0.1,0.4l-2.1,2.8l2.4,3c0.1,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.2h-1.1
        c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.4l-1.9-2.4v2.3c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2h-0.7
        C38.6,11.4,38.4,11.3,38.3,11.2z M19.5,6.5c-0.2,0-0.3-0.1-0.5-0.2C18.8,6.1,18.5,6,18.1,6c-0.5,0-0.8,0.1-0.8,0.4
        c0,0.2,0.2,0.3,0.6,0.5l1.3,0.4c0.7,0.2,1.3,0.5,1.6,0.8c0.3,0.3,0.5,0.8,0.5,1.3c0,0.7-0.3,1.3-0.8,1.6c-0.5,0.4-1.3,0.6-2.2,0.6
        c-1.5,0-2.5-0.5-2.9-1.4c-0.1-0.2-0.1-0.3,0-0.5c0.1-0.1,0.2-0.2,0.5-0.3l0.9-0.1h0c0.2,0,0.3,0.1,0.5,0.2
        c0.2,0.3,0.6,0.4,1.1,0.4c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.2,0.3-0.4c0-0.2-0.2-0.4-0.7-0.5l-1.3-0.4c-0.7-0.2-1.2-0.4-1.5-0.7
        c-0.3-0.3-0.4-0.7-0.4-1.2c0-0.7,0.3-1.3,0.8-1.7c0.5-0.4,1.2-0.5,2.1-0.5c1.4,0,2.3,0.4,2.7,1.2c0.1,0.2,0.1,0.3,0,0.5
        c-0.1,0.1-0.2,0.2-0.4,0.3L19.5,6.5L19.5,6.5z M22,4.8c0.1-0.1,0.3-0.2,0.5-0.2h0.7c0.2,0,0.3,0.1,0.5,0.2
        c0.1,0.1,0.2,0.2,0.2,0.4c0.6-0.5,1.3-0.7,2.1-0.7c1,0,1.8,0.3,2.4,1c0.6,0.7,1,1.5,1,2.5c0,1-0.3,1.9-1,2.5c-0.6,0.7-1.4,1-2.4,1
        c-0.8,0-1.5-0.2-2.1-0.7v2.8c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2h-0.7c-0.2,0-0.4-0.1-0.5-0.2
        c-0.1-0.1-0.2-0.3-0.2-0.5V5.2C21.8,5.1,21.8,4.9,22,4.8z M24.4,6.8C24,7.2,23.9,7.5,23.9,8c0,0.5,0.2,0.8,0.5,1.1
        c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.1-0.5
        S24.7,6.5,24.4,6.8z M33.6,4.4c-1.1,0-2,0.3-2.8,1C30.1,6.1,29.7,7,29.7,8c0,1,0.4,1.9,1.1,2.5c0.7,0.7,1.7,1,2.8,1
        c1.1,0,2-0.3,2.8-1C37.1,9.9,37.4,9,37.4,8c0-1-0.4-1.9-1.1-2.5C35.6,4.8,34.7,4.4,33.6,4.4z M33.6,9.6c-0.5,0-0.9-0.2-1.2-0.5
        C32,8.9,31.9,8.5,31.9,8c0-0.5,0.2-0.8,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
        s-0.2,0.8-0.5,1.2C34.4,9.5,34,9.6,33.6,9.6z M47.4,9.6c-0.3-0.2-0.5-0.5-0.7-0.9h4.7c0.2,0,0.4-0.1,0.5-0.2
        c0.1-0.1,0.2-0.3,0.2-0.5c0-1-0.3-1.9-1.1-2.6c-0.7-0.7-1.7-1.1-2.8-1.1c-1.1,0-2,0.3-2.7,1C44.9,6.1,44.5,7,44.5,8
        s0.4,1.9,1.1,2.5c0.8,0.7,1.7,1,2.8,1c1.3,0,2.3-0.4,3.1-1.3c0.1-0.1,0.2-0.3,0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.3l-1-0.2
        c0,0-0.1,0-0.1,0c-0.2,0-0.3,0.1-0.5,0.2c-0.3,0.3-0.7,0.4-1.2,0.4C48.1,9.9,47.7,9.8,47.4,9.6z M48.4,6.1c0.4,0,0.7,0.1,1,0.3
        c0.3,0.2,0.5,0.5,0.7,0.8h-3.3c0.1-0.3,0.3-0.6,0.6-0.8C47.7,6.2,48,6.1,48.4,6.1z M53.3,4.9c0-0.1,0-0.1,0.1-0.2
        c0.1-0.1,0.1-0.1,0.2-0.1h0.3c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v1c0.6-1,1.5-1.4,2.8-1.4c0.8,0,1.5,0.3,2.1,0.8
        c0.6,0.5,0.8,1.2,0.8,2v3.9c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-0.3c-0.2,0-0.3-0.1-0.3-0.3V7.4c0-0.6-0.2-1.1-0.7-1.5
        c-0.4-0.4-1-0.6-1.6-0.6c-0.7,0-1.3,0.2-1.8,0.6c-0.5,0.4-0.7,0.9-0.7,1.6v3.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-0.3
        c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2L53.3,4.9L53.3,4.9z M65.1,4.5c-1.1,0-2,0.3-2.7,1C61.7,6.2,61.4,7,61.4,8
        s0.4,1.8,1.1,2.5c0.7,0.7,1.6,1,2.7,1s2-0.3,2.7-1C68.5,9.8,68.8,9,68.8,8s-0.4-1.8-1.1-2.5C67.1,4.8,66.2,4.5,65.1,4.5z
          M65.1,10.7c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.8-1.2-0.8-1.9c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,2-0.8c0.8,0,1.5,0.3,2,0.8
        C67.7,6.6,68,7.2,68,8c0,0.8-0.3,1.4-0.8,1.9C66.6,10.4,65.9,10.7,65.1,10.7z M69.4,5.1V4.9c0-0.2,0.1-0.2,0.3-0.2h1V3.5
        c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h0.3c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v1.1H74c0.2,0,0.3,0.1,0.3,0.2v0.2
        c0,0.2-0.1,0.2-0.3,0.2h-2.5v4.1c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3h0.7c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.2v0.3
        c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1h-0.8c-0.5,0-1-0.2-1.4-0.6c-0.4-0.4-0.6-0.8-0.6-1.4V5.3h-1C69.5,5.3,69.4,5.3,69.4,5.1
        L69.4,5.1z M76.3,10c-0.6-0.5-0.9-1.1-0.9-1.8h6.2c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0-1-0.4-1.8-1.1-2.5
        c-0.7-0.7-1.6-1-2.6-1c-1,0-1.9,0.3-2.6,1C74.9,6.2,74.5,7,74.5,8s0.4,1.8,1.1,2.5c0.7,0.7,1.6,1,2.7,1c0.6,0,1.2-0.1,1.8-0.4
        c0.5-0.2,1-0.6,1.3-1.1c0-0.1,0.1-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1l-0.4-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0.1
        c-0.5,0.7-1.3,1-2.3,1C77.5,10.8,76.9,10.5,76.3,10z M78.2,5.3c0.7,0,1.3,0.2,1.9,0.7c0.5,0.4,0.8,1,0.9,1.7h-5.6
        c0.1-0.7,0.4-1.2,0.9-1.7C76.9,5.5,77.5,5.3,78.2,5.3z M5.3,7.6V2.3H1.3C0.6,2.3,0,2.8,0,3.5v4.1H5.3z M11.4,7.6H6.1V2.3h4.1
        c0.7,0,1.3,0.5,1.3,1.2V7.6z M6.1,8.3v5.3h4.1c0.7,0,1.3-0.5,1.3-1.2V8.3H6.1z M0,8.3h5.3v5.3l-2.1,1.9c-0.2,0.2-0.5,0.1-0.5-0.2
        v-1.7H1.3C0.6,13.6,0,13,0,12.4V8.3z"
          />
        </g>
      </g>
    </svg>

  );
}

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';

import Button from './Button';
import RecorderPreview from './RecorderPreview';
import Countdown from './Countdown';

const ONE_MINUTE = 60;
const FIVE_MINUTES = ONE_MINUTE * 5;
const MAX_RECORDING_TIME = FIVE_MINUTES;

function calculateTimeDuration(secs) {
  const min = Math.floor(secs / ONE_MINUTE);
  let sec = Math.floor(secs - (min * ONE_MINUTE));

  if (sec < 10) {
    sec = `0${sec}`;
  }

  return `${min}:${sec}`;
}
const MAX_RECORDING_DISPLAY = calculateTimeDuration(MAX_RECORDING_TIME);

export function isMediaRecorderCompatible() {
  return navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window.MediaRecorder;
}

export function getRecordMimeType() {
  if (!isMediaRecorderCompatible()) {
    return null;
  }
  const { isTypeSupported } = window.MediaRecorder;
  if (isTypeSupported('video/webm;codecs=vp9')) {
    return 'video/webm;codecs=vp9';
  } if (isTypeSupported('video/webm')) {
    return 'video/webm';
  } if (isTypeSupported('video/mp4')) {
    return 'video/mp4';
  }

  return null;
}

function fileFromBlob(blob) {
  if (!blob) return null;
  let fileExtension;
  switch (blob.type) {
    case 'image/png':
      fileExtension = 'png';
      break;
    case 'video/mp4':
      fileExtension = 'mp4';
      break;
    default:
      fileExtension = 'webm';
  }

  const file = new File(
    [blob],
    `recording.${fileExtension}`,
    { type: blob.type, lastModified: Date.now(), size: blob.size },
  );

  return file;
}

function MediaRecorder({
  stream, setStream, onClickCancel, mediaBlob, setMediaBlob, setFiles, isPhoto, timeLimitInSeconds,
}) {
  const recorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);

  const onClickCapture = useCallback(() => {
    if (recorderRef.current) {
      // capture photo from video stream
      const video = document.querySelector('.sn-block-video video');
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        setMediaBlob(blob);
        setIsRecording(false);
        recorderRef.current.stop();
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        setStream(null);
      });
    }
  }, [stream]);

  const onClickStart = useCallback(() => {
    if (recorderRef.current) {
      setIsCountingDown(!isCountingDown);
    }
  }, [isCountingDown]);

  const onClickStop = useCallback(() => {
    if (recorderRef.current) {
      setIsRecording(false);
      recorderRef.current.stop();
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);
    }
  }, [stream]);

  const startRecording = useCallback(() => {
    setIsRecording(true);
    setIsCountingDown(false);
    recorderRef.current.start();
  }, []);

  const checkForTimeWarnings = useCallback(() => {
    const wrapperElement = document.querySelector('.video-recording-timer');
    const timeLimit = timeLimitInSeconds || MAX_RECORDING_TIME;
    const remainingTime = timeLimit - elapsedTime;

    // Check if the class has already been added
    if (timeLimit > 180 && remainingTime <= 30 && !wrapperElement.classList.contains('time-warning')) {
      wrapperElement.classList.add('time-warning');
    } else if (timeLimit > 60 && timeLimit <= 180 && remainingTime <= 15 && !wrapperElement.classList.contains('time-warning')) {
      wrapperElement.classList.add('time-warning');
    } else if (timeLimit <= 60 && remainingTime <= 10 && !wrapperElement.classList.contains('time-warning')) {
      wrapperElement.classList.add('time-warning');
    }
  }, [elapsedTime, timeLimitInSeconds]);

  useEffect(() => {
    if (stream) {
      if (isMediaRecorderCompatible()) {
        recorderRef.current = new window.MediaRecorder(stream, { mimeType: getRecordMimeType() });
        recorderRef.current.addEventListener('dataavailable', (event) => {
          setMediaBlob(event.data);
        });
        // eslint-disable-next-line no-console
        recorderRef.current.addEventListener('warning', console.warn);
        // eslint-disable-next-line no-console
        recorderRef.current.addEventListener('error', console.error);
      } else {
        toast.error('Your browser does not support recording video. Please try a different browser.');
      }
    } else {
      recorderRef.current = null;
    }
  }, [stream, timeLimitInSeconds]);

  useEffect(() => {
    if (!isRecording && mediaBlob) {
      setFiles([fileFromBlob(mediaBlob)]);
    }
  }, [isRecording, mediaBlob]);

  useEffect(() => {
    if (isRecording) {
      checkForTimeWarnings();
      if (elapsedTime >= (timeLimitInSeconds || MAX_RECORDING_TIME)) {
        onClickStop();
        toast.success('Video recording stopped. Maximum recording time reached.');
      }

      setTimeout(() => {
        setElapsedTime(elapsedTime + 1);
      }, 1000);
    } else {
      setElapsedTime(0);
    }
  }, [isRecording, elapsedTime]);

  return (
    <>
      <div className={`sn-block-video ${isRecording ? 'recording' : 'stopped'} ${isCountingDown && 'counting-down'}`}>
        {!mediaBlob && (
          <div className="recorder-mirror">
            <RecorderPreview stream={stream} />
          </div>
        )}
        {!mediaBlob && isPhoto && (
          <Button
            onClick={onClickCapture}
            buttonId="btn-record-wrapper"
            ariaLabel="Capture"
          >
            <div className="btn-record" title="Capture">
              <div className="btn-record-icon" />
            </div>
          </Button>
        )}
        {!mediaBlob && !isPhoto && (
          <>
            {isCountingDown && (
              <Countdown
                isCountingDown={isCountingDown}
                onCountdownComplete={() => {
                  startRecording();
                }}
              />
            )}
            <Button
              onClick={isRecording ? onClickStop : onClickStart}
              buttonId="btn-record-wrapper"
              ariaLabel={isRecording ? 'Stop' : 'Record'}
            >
              <div className="btn-record" title={isRecording ? 'Stop' : 'Record'}>
                <div className="btn-record-icon" />
              </div>
            </Button>
            <div className="start-recording-prompt">Press record when ready</div>
            <div className={`video-recording-timer ${isRecording ? 'recording' : 'stopped'}`}>
              <div className="recording-time">{calculateTimeDuration(elapsedTime)}</div>
              <div className="max-time">
                /
                {' '}
                {timeLimitInSeconds ? calculateTimeDuration(timeLimitInSeconds) : MAX_RECORDING_DISPLAY}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="video-actions-container">
        {!isCountingDown && !isRecording && !mediaBlob && (
          <Button
            onClick={onClickCancel}
            primaryOutline
            ariaLabel="Cancel"
          >
            Cancel
          </Button>
        )}
      </div>
    </>
  );
}

export default MediaRecorder;

import React from 'react';

import SpokenoteStickerAnimated from '../../images/spokenote-sticker-animated.svg';
import ReportIssue from '../ReportIssue';

function NoteErrorNotFoundState() {
  return (
    <div className="error">
      <div className="spokenote-sticker-visual">
        <SpokenoteStickerAnimated />
      </div>
      <div className="spokenote-sticker-visual-title">
        <h4>Error State</h4>
        <p>
          {`The page you are looking for does not exist.
          If you believe this was an error, please use the "Report Issue" tool below to send us a message.`}
        </p>
        <ReportIssue />
      </div>
    </div>
  );
}

export default NoteErrorNotFoundState;

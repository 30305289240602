import React from 'react';
import styles from '../components/VideoPlayer/VideoPlayer.module.scss';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className={`${styles.iconPlay}`} x="0px" y="0px" viewBox="0 0 50 50">
      <title>Play</title>
      <path className="st0" d="M43.1,21.5l-24.2-14c-2.7-1.5-6,0.4-6,3.5V39c0,3.1,3.3,5,6,3.5l24.2-14C45.8,26.9,45.8,23.1,43.1,21.5z"/>
    </svg>
  );
}

import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" className="nav-icon" x="0px" y="0px" viewBox="0 0 24 24">
      <title>Shop</title>
      <circle className="st0" cx="10.5" cy="21" r="1.4" />
      <circle className="st0" cx="19.5" cy="21" r="1.4" />
      <path
        className="st1"
        d="M1.6,2.7h2c0.7,0,1.4,0.5,1.6,1.2l3.4,11c0.1,0.5,0.6,0.8,1.1,0.8h11c0.5,0,1-0.4,1.1-0.9l1.3-6
	c0.2-0.7-0.4-1.4-1.1-1.4H6.3"
      />
    </svg>
  );
}

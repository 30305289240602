import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="btn-icon icon-record" x="0px" y="0px" viewBox="0 0 26 24">
      <title>record</title>
      <g>
        <g>
          <path
            className="st0"
            d="M15,19.7H4c-1.6,0-2.9-1.3-2.9-2.9V7.2c0-1.6,1.3-2.9,2.9-2.9h11c1.6,0,2.9,1.3,2.9,2.9v9.6
C17.8,18.4,16.6,19.7,15,19.7z"
          />
          <path className="st0" d="M23,18l-5.2-4.3V9.5l5.2-3.8c0.8-0.6,1.8,0,1.8,0.9v10.5C24.9,18.1,23.8,18.7,23,18z" />
        </g>
      </g>
    </svg>
  );
}

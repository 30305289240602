import React from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

function SpokenoteVideo({ block, note, onPlayCallback }) {
  const playerRef = React.useRef(null);
  const [loadingVideo, setLoadingVideo] = React.useState(true);

  React.useEffect(() => {
    if (!playerRef.current) return;

    if (playerRef && playerRef.current) {
      const HAS_METADATA = 1;
      if (playerRef.current.readyState >= HAS_METADATA) {
        setLoadingVideo(false);
      }
      playerRef.current.addEventListener('loadedmetadata', () => {
        setLoadingVideo(false);
      });
    }
  }, [playerRef]);

  React.useEffect(() => {
    setTimeout(() => {
      // TODO turn this into a canary of something bigger may be the matter.
      setLoadingVideo(false);
    }, 6000);
  }, []);

  return (
    <div className="sn-block sn-block-video">
      <VideoPlayer
        ref={playerRef}
        src={block.data.processedUrl}
        poster={block.data.supportingUrl}
        isReady={!loadingVideo}
        onPlayCallback={onPlayCallback}
        resolution={block.data.resolution}
      />
    </div>
  );
}

export default SpokenoteVideo;

import React from 'react';
import { Link } from 'react-router-dom';

import SpokenoteStickerAnimated from '../../images/spokenote-sticker-animated.svg';

function NoteClaimedState({ page }) {
  return (
    <div>
      <div className="spokenote-sticker-visual">
        <SpokenoteStickerAnimated />
      </div>
      <div className="spokenote-sticker-visual-title">
        <h4>Page Claimed</h4>
        <p>
          This page is claimed. If you are the owner of this page, please
          {' '}
          <Link to="/login">login</Link>
          {' '}
          to add content to the page.
        </p>
      </div>
    </div>
  );
}

export default NoteClaimedState;

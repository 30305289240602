import React, { useCallback } from 'react';

import TextIcon from '../../images/blocks/icon-text.svg';
import PhoneIcon from '../../images/blocks/icon-phone.svg';
import EmailIcon from '../../images/blocks/icon-email.svg';
import LocationIcon from '../../images/blocks/icon-location.svg';
import CompanyIcon from '../../images/blocks/icon-company.svg';

function LinkList({ block, onClickCallback }) {
  const { data } = block;

  const getIcon = useCallback((name) => {
    switch (name) {
      case 'text':
        return <TextIcon alt={name} />;
      case 'phone':
        return <PhoneIcon alt={name} />;
      case 'email':
        return <EmailIcon alt={name} />;
      case 'location':
        return <LocationIcon alt={name} />;
      case 'company':
        return <CompanyIcon alt={name} />;
      default:
        return null;
    }
  }, []);

  return (
    <div className="sn-block sn-block-link-list">
      {data.items?.map((item, itemIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="list-item" key={`${item.iconName}-${itemIndex}`}>
          <div className="list-item-icon">
            {getIcon(item.iconName)}
          </div>
          <div className="list-item-info">
            {item.message && (
              <div className="list-item-message">
                {item.message.split('\n').map((line, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={`item-${itemIndex}-message-line-${index}`}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            )}
            {item.linkUrl && (
              <a
                target="_blank"
                rel="noreferrer"
                href={item.linkUrl}
                className="list-item-link"
                onClick={() => onClickCallback(block, item.linkUrl, item.linkText)}
              >
                {item.linkText || item.linkUrl}
              </a>
            )}
            {item.subtitle && (
              <div className="list-item-subtitle">{item.subtitle}</div>
            )}
            {item.buttonUrl && (
              <a
                target="_blank"
                rel="noreferrer"
                href={item.buttonUrl}
                className="link"
                onClick={() => onClickCallback(block, item.buttonUrl, item.buttonText)}
              >
                {item.buttonText}
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default LinkList;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchMedium = void 0;
exports.BatchMedium = {
    DIGITAL: 'Digital',
    DIRECT_MAIL: 'Direct Mail',
    STICKERS: 'Stickers',
    BUSINESS_CARDS: 'Business Cards',
    THANK_YOU_CARDS: 'Thank You Cards',
    GREETING_CARDS: 'Greeting Cards',
    GIFT_CARDS: 'Gift Cards',
    STATIONARY: 'Stationary',
    PROMOTIONAL_PRODUCTS: 'Promotional Products',
    OTHER: 'Other',
};
exports.default = exports.BatchMedium;

import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import SpokenoteVideo from './SpokenoteVideo';
import Button from './Button';
import DynamicVideo from './DynamicVideo';
import Image from './Image';
import Text from './Text';
import LinkList from './LinkList';
import ContactCard from './ContactCard';
import VCard from './VCard';
import SocialLinks from './SocialLinks';
import { createClickEvent, createPlayEvent, createVcardDownloadEvent, shouldTrackEvent } from '../../libs/user-events';

function Note({ page, note }) {
  const [searchParams] = useSearchParams();
  const { currentUser } = useAuth();

  const trackPlayEvent = React.useCallback((block) => {
    if (shouldTrackEvent(page, note, currentUser?.id, searchParams)) {
      createPlayEvent(page.id, note.id, block, currentUser?.id);
    }
  }, [page, note]);

  const trackClickEvent = React.useCallback((block, destination = null, text = null) => {
    if (shouldTrackEvent(page, note, currentUser?.id, searchParams)) {
      createClickEvent(page.id, note.id, block, currentUser?.id, destination, text);
    }
  }, [page, note]);

  const trackDownloadEvent = React.useCallback((block) => {
    if (shouldTrackEvent(page, note, currentUser?.id, searchParams)) {
      createVcardDownloadEvent(page.id, note.id, block, currentUser?.id);
    }
  }, [note]);

  const buildBlock = React.useCallback((block, index) => {
    switch (block.type) {
      case 'spokenote-video':
        return (
          <SpokenoteVideo
            key={`${block.type}-${index}`}
            block={block}
            note={note}
            onPlayCallback={() => trackPlayEvent(block)}
          />
        );
      case 'button':
        return (
          <Button
            key={`${block.type}-${index}`}
            block={block}
            page={page}
            onClickCallback={trackClickEvent}
          />
        );
      case 'dynamic-video':
        return <DynamicVideo key={`${block.type}-${index}`} block={block} onPlayCallback={trackPlayEvent} />;
      case 'image':
        return <Image key={`${block.type}-${index}`} block={block} />;
      case 'text':
        return <Text key={`${block.type}-${index}`} block={block} />;
      case 'link-list':
        return <LinkList key={`${block.type}-${index}`} block={block} onClickCallback={trackClickEvent} />;
      case 'contact-card':
        return <ContactCard key={`${block.type}-${index}`} block={block} onClickCallback={trackClickEvent} />;
      case 'vcard':
        return <VCard key={`${block.type}-${index}`} block={block} onClickCallback={trackDownloadEvent} />;
      case 'social-links':
        return <SocialLinks key={`${block.type}-${index}`} block={block} onClickCallback={trackClickEvent} />;
      default:
        return null;
    }
  }, [note]);

  if (!note) {
    return null;
  }

  return (
    <div className={`note-${note.id}`}>
      {note.denormalized.blocks.map(buildBlock)}
    </div>
  );
}

export default Note;

import React from 'react';
import styles from '../components/VideoPlayer/VideoPlayer.module.scss';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className={`${styles.iconPause}`} x="0px" y="0px" viewBox="0 0 50 50">
      <title>Pause</title>
      <path className="st0" d="M17.4,43h-4c-2.2,0-4-1.8-4-4V11.1c0-2.2,1.8-4,4-4h4c2.2,0,4,1.8,4,4v27.9C21.4,41.2,19.6,43,17.4,43z"/>
      <path className="st0" d="M36.6,43h-4c-2.2,0-4-1.8-4-4V11.1c0-2.2,1.8-4,4-4h4c2.2,0,4,1.8,4,4v27.9C40.6,41.2,38.8,43,36.6,43z"/>
    </svg>
  );
}

import React, { useEffect, useState } from 'react';

function Countdown({ isCountingDown, onCountdownComplete }) {
  const [countdownSecondsRemaining, setCountdownSecondsRemaining] = useState(null);

  useEffect(() => {
    if (isCountingDown) {
      setCountdownSecondsRemaining(3);
    } else {
      setCountdownSecondsRemaining(null);
    }
  }, [isCountingDown]);

  useEffect(() => {
    if (countdownSecondsRemaining === 0) {
      if (onCountdownComplete) {
        onCountdownComplete();
      }
    } else if (countdownSecondsRemaining > 0) {
      const timeout = setTimeout(() => {
        setCountdownSecondsRemaining(countdownSecondsRemaining - 1);
      }, 1000);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [countdownSecondsRemaining, onCountdownComplete]);

  return isCountingDown ? (
    <div className="record-video-countdown">
      {countdownSecondsRemaining}
    </div>
  ) : null;
}

export default Countdown;

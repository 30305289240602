"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchType = void 0;
exports.BatchType = {
    DIY: 'DIY',
    STICKERS: 'Stickers',
    CUSTOM: 'Custom',
    SALES: 'Sales',
    SYSTEM: 'System',
    TEST: 'Test',
};
exports.default = exports.BatchType;

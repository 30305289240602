/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';

const useVideoPlayer = (videoElement) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    isPlayingInitiated: false,
    progress: 0,
    speed: 1,
    isMuted: false,
  });

  const initiatePlay = useCallback(() => {
    setPlayerState({
      ...playerState,
      isPlayingInitiated: playerState.isPlayingInitiated = true,
      isPlaying: !playerState.isPlaying,
    });
  }, [playerState]);

  const togglePlay = useCallback(() => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  }, [playerState]);

  const handleVideoEnd = useCallback(() => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
      progress: 100,
    });
  }, [playerState]);

  const handleOnTimeUpdate = useCallback(() => {
    if (videoElement.current.duration === 0 || Number.isNaN(videoElement.current.duration)) return;
    const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
    setPlayerState({
      ...playerState,
      progress,
    });
  }, [playerState]);

  const handleVideoProgress = useCallback((event) => {
    const manualChange = Number(event.target.value);
    if (videoElement.current.duration === 0 || Number.isNaN(manualChange)) return;
    videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  }, [playerState]);

  const handleVideoSpeed = useCallback((event) => {
    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed,
    });
  }, [playerState]);

  const toggleMute = useCallback(() => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  }, [playerState]);

  const toggleFullscreen = useCallback(() => {
    if (videoElement.current) {
      if (videoElement.current.requestFullscreen) { // Standard
        videoElement.current.requestFullscreen();
      } else if (videoElement.current.webkitEnterFullscreen) { // Webkit 5+ (all iOS browsers)
        videoElement.current.webkitEnterFullscreen();
      } else if (videoElement.current.webkitRequestFullscreen) { // DEPRECATED Webkit
        videoElement.current.webkitRequestFullscreen();
      } else if (videoElement.current.mozRequestFullScreen) { // DEPRECATED Mozilla
        videoElement.current.mozRequestFullScreen();
      } else if (videoElement.current.msRequestFullscreen) { // DEPRECATED IE/Edge
        videoElement.current.msRequestFullscreen();
      } else {
        toast.error('Fullscreen not supported on this device');
      }
    }
  }, [videoElement]);

  const handleFullscreenChange = useCallback((e) => {
    setPlayerState({
      ...playerState,
      isPlaying: !e.target.paused,
    });
  }, [playerState]);

  const webkitHandleFullscreenChange = useCallback((e) => {
    // mobile webkit browsers pause video on fullscreen exit
    setPlayerState({
      ...playerState,
      isPlaying: false,
    });
  }, [playerState]);

  useEffect(() => {
    if (!videoElement.current) return;

    if (playerState.isPlaying) {
      videoElement.current.play();
    } else {
      videoElement.current.pause();
    }
  }, [playerState.isPlaying, videoElement]);

  useEffect(() => {
    if (!videoElement.current) return;
    videoElement.current.muted = playerState.isMuted;
  }, [playerState.isMuted, videoElement]);

  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.addEventListener('webkitendfullscreen', webkitHandleFullscreenChange);
    }
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      if (videoElement.current) {
        videoElement.current.removeEventListener('webkitendfullscreen', () => {});
      }
      document.removeEventListener('fullscreenchange', () => {});
      document.removeEventListener('webkitfullscreenchange', () => {});
      document.removeEventListener('mozfullscreenchange', () => {});
      document.removeEventListener('MSFullscreenChange', () => {});
    };
  }, [videoElement]);

  return {
    playerState,
    initiatePlay,
    togglePlay,
    handleVideoEnd,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    toggleFullscreen,
  };
};

export default useVideoPlayer;

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="btn-icon icon-record" x="0px" y="0px" viewBox="0 0 26 24">
      <title>upload</title>
      <polyline className="st0" points="8.7,8.7 13,4.3 17.4,8.7" />
      <line className="st0" x1="13" y1="4.3" x2="13" y2="14.4" />
      <path className="st0" d="M1.1,11.6v5.2c0,1.6,1.3,2.9,2.9,2.9H22c1.6,0,2.9-1.3,2.9-2.9v-5.2" />
    </svg>
  );
}

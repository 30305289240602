import React from 'react';
import Markdown from 'react-markdown';

import SpokenoteStickerAnimated from '../../images/spokenote-sticker-animated.svg';

function NoteErrorNotFoundState() {
  const [, setStatusData] = React.useState(null);

  React.useEffect(() => {
    fetch('https://status.spokenote.com/summary.json')
      .then((response) => response.json())
      .then((data) => {
        setStatusData(data);
      });
  }, []);

  return (
    <div className="error">
      <div className="spokenote-sticker-visual">
        <SpokenoteStickerAnimated />
      </div>
      <div className="spokenote-sticker-visual-title">
        <h4>Service Outage</h4>
        <Markdown>
          We are currently experiencing issues connecting to our servers.
          Please check our [status page](https://status.spokenote.com) for updates.
        </Markdown>

        <iframe
          title="Spokenote Status"
          src="https://status.spokenote.com/embed-status/7b9a5668/light-md"
          width="350"
          height="61"
          frameBorder="0"
          scrolling="no"
          style={{ border: 'none' }}
        />
      </div>
    </div>
  );
}

export default NoteErrorNotFoundState;

import React from 'react';

import SpokenoteStickerAnimated from '../../images/spokenote-sticker-animated.svg';

function NoteUnderReviewState({ page }) {
  return (
    <div>
      <div className="spokenote-sticker-visual">
        <SpokenoteStickerAnimated />
      </div>
      <div className="spokenote-sticker-visual-title">
        <h4>Page Under Review</h4>
        <p>
          The content of this page is under review. If you are the owner of this page, please contact
          {' '}
          <a href="mailto:support@spokenote.com">support</a>
          {' '}
          with any questions.
        </p>
      </div>
    </div>
  );
}

export default NoteUnderReviewState;

import React from 'react';
import { useSpring, animated } from 'react-spring';

import Loader from './Loader';

import './Button.module.scss';

const BUTTONTYPES = {
  PRIMARY: 'btn-primary',
  PRIMARYOUTLINE: 'btn-primary-outline',
  SECONDARY: 'btn-secondary',
  ALTERNATIVE: 'btn-alternative-options',
  NEGATIVE: 'btn-negative',
  NORMAL: '',
};

const BUTTONSIZE = {
  LARGE: 'btn-large',
  MEDIUM: 'btn-medium',
  SMALL: 'btn-small',
  FULL_WIDTH: 'full-width btn-medium',
  HALF_WIDTH: 'half-width btn-medium',
};

export default function AnimatedButton({ isLoading, children, ...props }) {
  /* showLoader is used to stay in the "isLoading state" a bit longer to avoid loading flashes
   if the loading state is too short. */
  const [showLoader, setShowLoader] = React.useState(false);

  let buttonType = BUTTONTYPES.NORMAL;
  if (props.primary) buttonType = BUTTONTYPES.PRIMARY;
  if (props.primaryOutline) buttonType = BUTTONTYPES.PRIMARYOUTLINE;
  if (props.secondary) buttonType = BUTTONTYPES.SECONDARY;
  if (props.alternative) buttonType = BUTTONTYPES.ALTERNATIVE;
  if (props.negative) buttonType = BUTTONTYPES.NEGATIVE;

  let buttonSizes = BUTTONSIZE.MEDIUM;
  if (props.large) buttonSizes = (BUTTONSIZE.LARGE);
  if (props.small) buttonSizes = (BUTTONSIZE.SMALL);
  /* Capture the dimensions of the button before the loading happens
  so it doesn’t change size. */
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, [children]);

  React.useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    }

    // Show loader a bits longer to avoid loading flash
    if (!isLoading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [isLoading, showLoader]);

  // Hooks used to fade in/out the loader or the button contents
  const fadeOutProps = useSpring({ display: 'flex', flexDirection: 'row', opacity: showLoader ? 1 : 0 });
  const fadeInProps = useSpring({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    minHeight: '24px',
    opacity: showLoader ? 0 : 1,
  });

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      id={props.buttonId}
      className={`btn ${buttonType} ${buttonSizes} ${showLoader ? 'btn-loading' : ''}`}
      type="button"
      ref={ref}
      aria-label={props.ariaLabel}
      style={
        showLoader
          ? {
            width: `${width}px`,
            height: `${height}px`,
          }
          : {}
      }
      data-tip={props.dataTip}
      data-for={props.dataFor}
    >
      {showLoader ? (
        <animated.div style={fadeOutProps} className="btn-content">
          <Loader />
        </animated.div>
      ) : (
        <animated.div style={fadeInProps} className="btn-content">{children}</animated.div>
      )}
    </button>
  );
}

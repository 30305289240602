import React, { useEffect, useRef } from 'react';

function RecorderPreview({ stream }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef && videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <div className="video-wrapper">
      <video
        key="recorder-preview"
        ref={videoRef}
        autoPlay
        playsInline
        muted
        controlsList="nodownload"
      >
        Sorry, your browser does not support embedded videos.
        We can not display your recording preview.
      </video>
    </div>
  );
}

export default RecorderPreview;

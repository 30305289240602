"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchIndustry = void 0;
exports.BatchIndustry = {
    AGENCIES_AND_CONSULTANTS: 'Agencies and Consultants',
    AUTOMOTIVE: 'Automotive',
    CONSUMER_PACKAGED_GOODS: 'Consumer Packaged Goods',
    E_COMMERCE: 'E-commerce',
    EDUCATION: 'Education',
    ENTERTAINMENT: 'Entertainment',
    EVENTS: 'Events',
    FINANCE: 'Finance',
    FOOD_AND_BEVERAGES: 'Food and Beverages',
    FUNDRAISING: 'Fundraising',
    GABLING_AND_CASINOS: 'Gabling and Casinos',
    GOVERNMENT: 'Government',
    HEALTH_AND_WELLNESS: 'Health and Wellness',
    HEALTHCARE: 'Healthcare',
    HOSPITALITY: 'Hospitality',
    MUSEUMS_AND_INSTITUTIONS: 'Museums and Institutions',
    NEWSPAPERS_AND_MAGAZINES: 'Newspapers and Magazines',
    NON_PROFIT: 'Non-Profit',
    OTHER: 'Other',
    PERSONAL_USE: 'Personal Use',
    POLITICAL_ORGANIZATION: 'Political Organization',
    REAL_ESTATE: 'Real Estate',
    RELIGIOUS_INSTITUTIONS: 'Religious Institutions',
    RESTAURANTS: 'Restaurants',
    RETAIL: 'Retail',
    SPOKENOTE_INTERNAL: 'Spokenote Internal',
    SPORTS: 'Sports',
    UNKNOWN: 'Unknown',
    WEDDINGS: 'Weddings',
};
exports.default = exports.BatchIndustry;

const useGTM = (gtmId) => {
  if (!gtmId) return;

  // Create the GTM script tag
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
  script.async = true;
  document.head.appendChild(script);

  // Insert the GTM initialization script
  const initScript = document.createElement('script');
  initScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gtmId}');
    `;
  document.head.appendChild(initScript);
};

export default useGTM;

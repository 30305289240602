"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaType = void 0;
exports.MediaType = {
    IMAGE: 'Image',
    VIDEO: 'Video',
    AUDIO: 'Audio',
    DOCUMENT: 'Document',
    OTHER: 'Other',
};
exports.default = exports.MediaType;

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="social-icon" x="0px" y="0px" viewBox="0 0 40 40">
      <title>YouTube</title>
      <circle className="st0" cx="20" cy="20" r="20" />
      <path
        className="st1"
        d="M30.9,15.6c-0.3-2.5-1.6-3-4.2-3.2c-2.2-0.2-4.7-0.3-6.6-0.3s-4.5,0.1-6.6,0.3c-2.5,0.2-3.9,0.6-4.2,3.2
	C9,16.7,9,18.3,9,20c0,1.6,0.1,3.3,0.2,4.4c0.3,2.6,1.7,3,4.2,3.2c2.2,0.2,4.7,0.3,6.6,0.3s4.4,0,6.6-0.3c2.5-0.2,3.8-0.6,4.2-3.2
	c0.2-1.1,0.3-2.8,0.3-4.4C31.1,18.3,31,16.7,30.9,15.6z M17.8,23.3v-6.7l5.8,3.3L17.8,23.3z"
      />
    </svg>
  );
}

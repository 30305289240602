import React from 'react';

import { configurePage } from '@repo/page-configurator';
import Markdown from 'react-markdown';
import SpokenoteStickerAnimated from '../../images/spokenote-sticker-animated.svg';

const TEN_SECONDS = 10 * 1000;

const DEFAULT_PROCESSING_TITLE = 'Processing';
const DEFAULT_PROCESSING_DESCRIPTION = 'You may safely leave this page or share your Spokenote page while we finish processing your upload.';

function NoteProcessingState({ getData, page }) {
  const config = React.useMemo(() => configurePage(page), [page]);

  React.useEffect(() => {
    const refreshInterval = setInterval(() => {
      getData();
    }, TEN_SECONDS);

    return () => clearInterval(refreshInterval);
  }, []);

  let image;
  if (config.topBanner) {
    image = (
      <img
        src={config.topBanner.imageUrl}
        alt={config.topBanner.imageAlt || 'Top Banner'}
        style={config.topBanner.maxWidth ? { maxWidth: `${config.topBanner.maxWidth}px` } : {}}
      />
    );
  }

  return (
    <div>
      {config.topBanner && (
        <div className="sponsor-banner">
          {config.topBanner.linkUrl ? (
            <a href={config.topBanner.linkUrl} target="_blank" rel="noreferrer">
              {image}
            </a>
          ) : (image)}
        </div>
      )}

      {!config.processingInstructions?.isStickerVisualDisabled && (
        <div className="processing-instructions spokenote-sticker-visual animated">
          <SpokenoteStickerAnimated />
        </div>
      )}
      <div className="spokenote-sticker-visual-title">
        <h4 className="color-primary">
          {config.processingInstructions?.title || DEFAULT_PROCESSING_TITLE}
        </h4>
        <div className="instructions-description" style={{ textAlign: config.processingInstructions?.descriptionTextAlignment || 'center' }}>
          <Markdown>
            {config.processingInstructions?.description || DEFAULT_PROCESSING_DESCRIPTION}
          </Markdown>
        </div>
      </div>
    </div>
  );
}

export default NoteProcessingState;

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="social-icon" x="0px" y="0px" viewBox="0 0 40 40">
      <title>TikTok</title>
      <circle className="st0" cx="20" cy="20" r="20" />
      <path
        className="st1"
        d="M29.5,14.5c-1.1-0.2-2.1-0.8-2.9-1.7c-1-1.1-1.5-2.5-1.5-3.8h-1.1h-2.9v9.4v6.5
	c0,1.9-1.5,3.4-3.4,3.4c-1.1,0-2.1-0.6-2.8-1.4c-0.4-0.6-0.6-1.3-0.6-2c0-1.9,1.5-3.4,3.4-3.4c0.4,0,0.7,0.1,1,0.2v-4
	c-0.3-0.1-0.7-0.1-1-0.1c-0.1,0-0.1,0-0.2,0c-4,0.1-7.2,3.4-7.2,7.3c0,1.9,0.8,3.7,2,5c1.4,1.4,3.3,2.3,5.4,2.3
	c4.1,0,7.4-3.3,7.4-7.3v-8.2c1.5,1.4,3.5,2,5.5,1.9v-4C30.3,14.6,29.9,14.6,29.5,14.5z"
      />
    </svg>
  );
}

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="video-player-icon" x="0px" y="0px" viewBox="0 0 20 20">
      <title>Fullscreen</title>
      <g>
        <path className="st0" d="M3.7,7V5c0-0.7,0.6-1.3,1.3-1.3h2" />
        <path className="st0" d="M13,3.7h2c0.7,0,1.3,0.6,1.3,1.3v2" />
        <path className="st0" d="M16.3,13v2c0,0.7-0.6,1.3-1.3,1.3h-2" />
        <path className="st0" d="M7,16.3H5c-0.7,0-1.3-0.6-1.3-1.3v-2" />
      </g>
    </svg>
  );
}

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="social-icon" x="0px" y="0px" viewBox="0 0 40 40">
      <title>Instagram</title>
      <circle className="st0" cx="20" cy="20" r="20"/>
      <g>
        <path className="st1" d="M20,13.7L20,13.7c-3.5,0-6.3,2.9-6.3,6.3c0,3.5,2.8,6.3,6.2,6.3H20c3.4,0,6.3-2.8,6.3-6.3
          C26.3,16.6,23.4,13.7,20,13.7z M20,24.2L20,24.2c-2.3,0-4.2-1.9-4.2-4.2c0-2.2,1.9-4.1,4.1-4.1H20c2.2,0,4.1,1.9,4.1,4.1
          C24.1,22.3,22.3,24.2,20,24.2z"/>
        <path className="st1" d="M25.3,8.5H14.7c-3.4,0-6.2,2.8-6.2,6.2v10.5c0,3.4,2.8,6.2,6.2,6.2h10.6c3.4,0,6.2-2.8,6.2-6.2V14.7
          C31.5,11.3,28.7,8.5,25.3,8.5z M29.5,25.2c0,2.4-1.9,4.3-4.3,4.3H14.8c-2.4,0-4.3-1.9-4.3-4.3V14.8c0-2.4,1.9-4.3,4.3-4.3h10.5
          c2.4,0,4.3,1.9,4.3,4.3V25.2z"/>
        <path className="st1" d="M26.2,12.5c-0.8,0-1.3,0.6-1.3,1.3c0,0.7,0.5,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3
          C27.5,13.1,26.9,12.5,26.2,12.5z"/>
      </g>
    </svg>
  );
}

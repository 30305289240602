import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="social-icon" x="0px" y="0px" viewBox="0 0 40 40">
      <title>Telegram</title>
      <circle className="st0" cx="20" cy="20" r="20" />
      <g>
        <path
          className="st1"
          d="M9.2,19.6c5-2.1,10.9-5,16.1-7c4.8-1.8,4.7-1.3,4,3.5c-0.6,3.8-1.2,7.7-1.8,11.5
		c-0.2,1.8-1.2,2.8-3.2,1.7L18,24.9c-0.9-0.7-0.6-1.3,0.1-2l5.8-5.4c1.5-1.5,0.8-2.1-1-0.9L15,21.9c-1.1,0.8-2.3,0.8-3.6,0.4l-2.7-1
		C6.9,20.7,8.3,20,9.2,19.6L9.2,19.6z"
        />
      </g>
    </svg>
  );
}

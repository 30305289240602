import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="btn-icon icon-check" x="0px" y="0px" viewBox="0 0 26 24">
      <title>checkmark</title>
      <g>
        <circle className="st0" cx="13" cy="12" r="10.1" />
        <polyline className="st0" points="9.2,12.7 11.9,15.5 17.8,9.6 " />
      </g>
    </svg>
  );
}

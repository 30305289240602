import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" className="link-list-icon" x="0px" y="0px" viewBox="0 0 20 20">
      <title>Location</title>
      <g>
        <path className="st0" d="M10,1.8c-3.2,0-5.9,2.6-5.9,5.9c0,2.1,2.4,6.5,4.5,9.8c0.3,0.5,0.8,0.8,1.4,0.8s1.1-0.3,1.4-0.8
          c2-3.3,4.5-7.6,4.5-9.8C15.9,4.4,13.2,1.8,10,1.8z M10.6,16.9c-0.2,0.4-0.9,0.4-1.1,0c-2.7-4.4-4.3-7.8-4.3-9.2
          C5.1,5,7.3,2.8,10,2.8c2.7,0,4.9,2.2,4.9,4.9C14.9,9.1,13.3,12.5,10.6,16.9z"/>
        <path className="st0" d="M10,5.4c-1.3,0-2.3,1-2.3,2.3S8.7,10,10,10s2.3-1,2.3-2.3S11.3,5.4,10,5.4z M10,9C9.3,9,8.7,8.4,8.7,7.7
          S9.3,6.4,10,6.4c0.7,0,1.3,0.6,1.3,1.3S10.7,9,10,9z"/>
      </g>
    </svg>
  );
}

import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import toast from 'react-hot-toast';
import { getFeatures } from '../libs/api';

const FeatureContext = createContext();

export function FeatureProvider({ children }) {
  const [features, setFeatures] = useState([]);

  // call this function when you want to authenticate the user
  const getFeaturesForWorkspace = useCallback(async () => {
    const response = await getFeatures();
    if (response.ok) {
      setFeatures(response.data.features);
    } else {
      toast.error('Something went wrong while getting features. Please refresh or try again later.');
    }
  }, []);

  const findFeature = useCallback((featureName) => features.find((f) => f.id === featureName), [features]);

  useEffect(() => {
    getFeaturesForWorkspace();
  }, []);

  const value = useMemo(() => ({
    features,
    findFeature,
    getFeaturesForWorkspace,
  }), [features]);
  return <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>;
}

export const useFeatures = () => useContext(FeatureContext);

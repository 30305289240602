import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" className="nav-icon" x="0px" y="0px" viewBox="0 0 24 24">
      <title>Spokenote</title>
      <path
        className="st0"
        d="M4.2,1.1C3.1,1.1,2.3,1.9,2.3,3v15.5c0,1.1,0.9,1.9,1.9,1.9h1.9v1.9c0,0.4,0.5,0.6,0.8,0.4l3.1-2.3h9.7
  c1.1,0,1.9-0.9,1.9-1.9V9.9l-8.8-8.8H4.2z"
      />
      <path className="st0" d="M12.9,1.1v6.9c0,1.1,0.9,1.9,1.9,1.9h6.9" />
      <polyline className="st0" points="12.9,6.2 7.2,6.2 7.2,15.9 16.8,15.9 16.8,9.9 " />
    </svg>
  );
}

import React from 'react';

export default function SvgComponent() {
  return (
    <svg version="1.1" className="link-list-icon" x="0px" y="0px" viewBox="0 0 20 20">
      <title>Phone</title>
      <g>
        <path className="st0" d="M14,17.1c-0.1,0-0.2,0-0.4,0c-1.3-0.3-4-1.2-6.7-4c-2.8-2.8-3.7-5.4-4-6.7C2.9,5.7,3,5.2,3.4,4.8l1.3-1.3
          c0.3-0.3,0.8-0.5,1.3-0.5C6.5,3,7,3.2,7.3,3.6l1,1.2c0.5,0.7,0.5,1.6-0.1,2.3L7.3,7.9c0.5,1,1.2,1.9,2.1,2.7
          c0.8,0.8,1.8,1.5,2.7,2.1l0.8-0.8c0.6-0.6,1.6-0.7,2.3-0.1l1.2,1c0.4,0.3,0.6,0.8,0.6,1.2c0,0.5-0.2,1-0.5,1.3l-1.3,1.3
          C14.9,16.9,14.5,17.1,14,17.1z M6,3.9C5.8,3.9,5.6,4,5.5,4.1L4.1,5.5C4,5.6,3.9,5.9,4,6.1C4.2,7.3,5,9.8,7.6,12.4
          c2.6,2.6,5,3.4,6.3,3.7c0.2,0,0.5,0,0.6-0.2l1.3-1.3c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.3-0.5l-1.2-1
          c-0.3-0.2-0.7-0.2-0.9,0.1l-1.1,1.1c-0.2,0.2-0.4,0.2-0.6,0.1c-1.2-0.6-2.3-1.4-3.3-2.4c-1-1-1.8-2.1-2.4-3.3
          C6.1,7.9,6.2,7.6,6.3,7.5l1.1-1.1c0.3-0.3,0.3-0.7,0.1-0.9l-1-1.2C6.4,4,6.2,4,6,3.9C6,3.9,6,3.9,6,3.9z"/>
      </g>
    </svg>
  );
}

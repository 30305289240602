import React from 'react';

function ContactCard({ block, onClickCallback }) {
  const { data } = block;
  const onButtonClick = (destination, text = null) => {
    window.open(destination, '_blank');
    onClickCallback(block, destination, text);
  };
  return (
    <div className="sn-block sn-block-contact-card">
      <div
        className="contact-card-content"
        style={{ backgroundColor: data.backgroundColor, color: data.isTextWhite ? '#FFF' : '#000' }}
      >
        {data.contactImageUrl && (
          <img className="contact-card-image" src={data.contactImageUrl} alt={data.contactName} />
        )}
        <div className="contact-card-name">{data.contactName}</div>
        <div className="contact-card-title">{data.contactTitle}</div>
        <div className="contact-card-buttons">
          {data.contactPhone && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`tel:${data.contactPhone}`}
              onClick={() => onButtonClick(`tel:${data.contactPhone}`, 'Call')}
              className="contact-card-button"
              style={{ color: data.isTextWhite ? '#FFF' : '#000' }}
            >
              Call
            </a>
          )}
          {data.contactText && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`sms:${data.contactText}`}
              onClick={() => onButtonClick(`sms:${data.contactText}`, 'Text')}
              className="contact-card-button"
              style={{ color: data.isTextWhite ? '#FFF' : '#000' }}
            >
              Text
            </a>
          )}
          {data.contactEmail && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`mailto:${data.contactEmail}`}
              onClick={() => onButtonClick(`mailto:${data.contactEmail}`, 'Email')}
              className="contact-card-button"
              style={{ color: data.isTextWhite ? '#FFF' : '#000' }}
            >
              Email
            </a>
          )}
          {data.contactAddress && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${data.contactAddress}`}
              onClick={() => onButtonClick(`https://www.google.com/maps/search/?api=1&query=${data.contactAddress}`, 'Directions')}
              className="contact-card-button"
              style={{ color: data.isTextWhite ? '#FFF' : '#000' }}
            >
              Directions
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactCard;

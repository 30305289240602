import React from 'react';

function Image({ block }) {
  const imageTag = (
    <img
      className="document-image"
      src={block.data.url}
      alt={block.data.text}
      style={block.data.maxWidth ? { maxWidth: `${block.data.maxWidth}px` } : {}}
    />
  );

  return (
    <div className="sn-block sn-block-image">
      {block.data.link ? (
        <a href={block.data.link} target="_blank" rel="noreferrer">
          {imageTag}
        </a>
      ) : (imageTag)}
    </div>
  );
}

export default Image;

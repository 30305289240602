import React, { useState, useEffect } from 'react';
import { browserName, browserVersion, isMobile } from 'react-device-detect';
import useLocalStorage from '../hooks/useLocalStorage';

function ReportIssue({ currentUser }) {
  const [date, setDate] = useState(new Date());
  const [accessUrls] = useLocalStorage('accessUrls');

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 15000);

    return function cleanup() {
      clearInterval(timer);
    };
  });

  const url = window.location.href;

  return (
    <a
      // eslint-disable-next-line max-len
      href={`https://airtable.com/shrUggJqx6CDtywsK?prefill_Access%20Urls=${accessUrls}&prefill_Status=Reported&prefill_URL=${url}&prefill_Time=${date.toISOString()}&prefill_Authentication%20status=${currentUser ? 'Yes' : 'No'}&prefill_Desktop%20or%20Mobile=${isMobile ? 'Mobile' : 'Desktop'}&prefill_Browser=${browserName}%20${browserVersion}`}
      target="_blank"
      rel="noreferrer"
    >
      Report Issue
    </a>
  );
}

export default ReportIssue;

import React from 'react';

export default function SvgComponent(props) {
  return (
    <svg version="1.1" className="video-player-icon" x="0px" y="0px" viewBox="0 0 20 20">
      <title>Unmuted</title>
      <path className="st0" d="M5.6,13.2H3.4c-0.8,0-1.4-0.6-1.4-1.4V7.9c0-0.8,0.6-1.4,1.4-1.4h2.2V13.2z" />
      <polygon className="st0" points="5.6,13.2 11.3,17.2 11.3,2.5 5.6,6.5 " />
      <path className="st0" d="M15.9,14.8c2.7-2.7,2.7-7.1,0-9.8" />
      <path className="st0" d="M14,12.1c1.2-1.2,1.2-3.2,0-4.5" />
    </svg>
  );
}
